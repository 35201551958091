import Intro from "../components/HomeComponents/Intro";
import Border from "../components/HomeComponents/utils/Border";
import ReadMe from "../components/HomeComponents/ReadMe";
import WorkExperience from "../components/HomeComponents/WorkExperience";
import Services from "../components/HomeComponents/Services";
import Education from "../components/HomeComponents/Education";
import Skills from "../components/HomeComponents/Skills";
import MyWork from "../components/HomeComponents/MyWork";
import Contact from "../components/HomeComponents/Contact";
import Copyright from "../components/HomeComponents/Copyright";

const Home = ({
    readmeRef,
    servicesRef,
    workExperienceRef,
    educationRef,
    skillsRef,
    myWorkRef,
    contactRef,
    isSideBarDisplayed,
}) => {
    return (
        <div
            className={`relative flex flex-col gap-[5vh] w-full no-scroll pl-[10%] pr-[5%]`}
        >
            <Intro />
            <div ref={readmeRef} id="readme" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <ReadMe isSideBarDisplayed={isSideBarDisplayed} />
            </div>
            <div ref={servicesRef} id="services" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <Services isSideBarDisplayed={isSideBarDisplayed} />
            </div>
            <div
                ref={workExperienceRef}
                id="work-experience"
                className="px-1.5 mb-8"
            >
                <Border />
            </div>

            <div>
                <WorkExperience />
            </div>
            <div ref={educationRef} id="education" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <Education />
            </div>
            <div ref={skillsRef} id="skills" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <Skills />
            </div>
            <div ref={myWorkRef} id="my-work" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <MyWork />
            </div>
            <div ref={contactRef} id="contact" className="px-1.5 mb-8">
                <Border />
            </div>

            <div>
                <Contact />
            </div>
            <Copyright />
        </div>
    );
};

export default Home;
