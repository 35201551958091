import { useEffect, useState } from "react";
import git from "../assets/git.png";
import { FcBusinessman, FcDocument } from "react-icons/fc";
import {
    VscChromeClose,
    VscGitCompare,
    VscSplitHorizontal,
} from "react-icons/vsc";

const MiniNavBar = ({
    miniActif,
    setMiniActif,
    miniList,
    handleDeleteMiniList,
    isSideBarDisplayed,
    handleMiniActifChanged,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [isHovered, setIsHovered] = useState(null);

    return (
        <div
            style={{
                position: "absolute",
                top: "35px",
                right: "0px",
                left:
                    isSideBarDisplayed && windowWidth > 1000 ? "190px" : "40px",
            }}
            className="flex justify-between z-40 h-[35px] bg-menuBackground text-gray-300 text-xs font-[500] border-b-[0.3px] border-gray-700 overflow-y-hidden overflow-x-scroll no-scroll"
        >
            {/*Elements on the MiniNavBar*/}
            <div className="flex">
                {miniList.map((one) => (
                    <div
                        key={one}
                        className={`${
                            one
                                ? `flex justify-start gap-[13px] items-center cursor-pointer h-[35px] w-fit px-[10px] select-none active:opacity-80 ${
                                      miniActif === one
                                          ? `bg-bodyBackground border-y border-b-bodyBackground border-t-myBlue`
                                          : ``
                                  }`
                                : ``
                        }`}
                        onMouseEnter={() => setIsHovered(one)}
                        onMouseLeave={() => setIsHovered(null)}
                        onClick={(e) => {
                            if (!e.target.closest(".absolute.rounded")) {
                                setMiniActif(one);
                                handleMiniActifChanged();
                            }
                        }}
                    >
                        {one && (
                            <div className="relative">
                                {/*Content*/}
                                <div className="flex gap-1.5 pr-[2vw]">
                                    {/*Icon*/}
                                    <div className="mt-[0.5px]">
                                        {one === "about_me.js" && (
                                            <FcBusinessman size={14} />
                                        )}
                                        {one === "Resume.js" && (
                                            <FcDocument size={14} />
                                        )}
                                        {one === ".gitignore" && (
                                            <img
                                                src={git}
                                                alt="git"
                                                className="min-w-[15px] h-[14px]"
                                            ></img>
                                        )}
                                    </div>

                                    {/*Text*/}
                                    <div>
                                        <p className="mb-[0.3px] text-[10px] footerCRLF:text-[11px] lmd:text-[13px]">
                                            {one}
                                        </p>
                                    </div>
                                </div>

                                {/*Close component*/}
                                <div
                                    onClick={() => handleDeleteMiniList(one)}
                                    className={`absolute rounded -right-2.5 rsm:-right-1 xl:right-0 top-[1px] flex justify-center items-center py-[2px] px-0.5 hover:bg-[#2d2f2e] ${
                                        isHovered === one ? "" : "hidden"
                                    }`}
                                >
                                    <VscChromeClose />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/*Right Three Elements*/}
            <div className="flex justify-end ml-4 mr-2 items-center h-[35px]">
                <div className="py-[3px] px-1 cursor-pointer mr-[0.2%] rounded-md hover:bg-[#2d2f2e]">
                    <VscGitCompare size={16} className="mb-[1px]" />
                </div>
                <div className="py-[3px] px-1 cursor-pointer mr-[0.2%] rounded-md hover:bg-[#2d2f2e]">
                    <VscSplitHorizontal size={16} />
                </div>
                <div className="pb-[4px] px-1 cursor-pointer mr-[0.2%] rounded-md hover:bg-[#2d2f2e]">
                    <p className="font-medium font-montagu">...</p>
                </div>
            </div>
        </div>
    );
};

export default MiniNavBar;
