import React from "react";

import { FcBusinessman } from "react-icons/fc";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const FolderComponent = ({
    name,
    path,
    whatIsClickedToBlueBachground,
    whatIsClickedInsidePublic,
    marginLeft,
}) => {
    return (
        <div
            className={`cursor-pointer px-[5%] ${
                whatIsClickedToBlueBachground === name
                    ? "bg-[#05395b] border-y border-[#1670AC]"
                    : "hover:bg-[#292d2e]"
            }`}
        >
            <div
                className={`flex items-center gap-0.5 ml-[${marginLeft}%] pl-[2.5%] border-l-[0.3px] border-gray-500`}
            >
                {whatIsClickedInsidePublic !== name ? (
                    <IoIosArrowForward size={13} className="mt-[0.5px]" />
                ) : (
                    <IoIosArrowDown size={13} className="mt-[0.5px]" />
                )}

                <div className="flex gap-1.5 items-center">
                    {name === "about_me" && <FcBusinessman />}
                    <p>{path}</p>
                </div>
            </div>
        </div>
    );
};

export default FolderComponent;
