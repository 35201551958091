import java from "../skills/java.webp";
import python from "../skills/python.png";
import js from "../skills/js.png";
import ts from "../skills/ts.png";
import html from "../skills/html.png";
import css from "../skills/css.png";
import bootstrap from "../skills/bootstrap.svg";
import tailwind from "../skills/tailwind.png";
import react from "../skills/react.png";
import vue from "../skills/vue.png";
import angular from "../skills/angular.png";
import node from "../skills/node.png";
import spring from "../skills/spring.png";
import mysql from "../skills/mysql.png";
import oracle from "../skills/oracle.png";
import postgresql from "../skills/postgresql.png";
import mongo from "../skills/mongo.png";
import docker from "../skills/docker.webp";
import k8s from "../skills/k8s.png";
import gitlab from "../skills/gitlab.png";
import prometheus from "../skills/prometheus.png";
import jenkins from "../skills/jenkins.png";
import git from "../skills/git.png";
import postman from "../skills/postman.svg";
import figma from "../skills/figma.png";

export const skills = [
    [
        {
            id: 1,
            name: "Java",
            icon: java,
        },
        {
            id: 2,
            name: "Python",
            icon: python,
        },
        {
            id: 3,
            name: "JavaScript",
            icon: js,
        },
        {
            id: 4,
            name: "TypeScript",
            icon: ts,
        },
    ],

    [
        {
            id: 1,
            name: "HTML5",
            icon: html,
        },
        {
            id: 2,
            name: "CSS3",
            icon: css,
        },
        {
            id: 3,
            name: "BootStrap",
            icon: bootstrap,
        },
        {
            id: 4,
            name: "TailwindCSS",
            icon: tailwind,
        },
    ],
    [
        {
            id: 1,
            name: "React",
            icon: react,
        },
        {
            id: 2,
            name: "Vue.js",
            icon: vue,
        },
        {
            id: 3,
            name: "Angular",
            icon: angular,
        },
    ],
    [
        {
            id: 1,
            name: "Node.js",
            icon: node,
        },
        {
            id: 2,
            name: "Spring Boot",
            icon: spring,
        },
    ],
    [
        {
            id: 1,
            name: "MySQL",
            icon: mysql,
        },
        {
            id: 2,
            name: "Oracle Database",
            icon: oracle,
        },
        {
            id: 3,
            name: "PostgreSQL",
            icon: postgresql,
        },
        {
            id: 4,
            name: "MongoDB",
            icon: mongo,
        },
    ],
    [
        {
            id: 1,
            name: "Docker",
            icon: docker,
        },
        {
            id: 2,
            name: "Kubernetes",
            icon: k8s,
        },
    ],
    [
        {
            id: 1,
            name: "GitLab",
            icon: gitlab,
        },
        {
            id: 2,
            name: "Jenkins",
            icon: jenkins,
        },
        {
            id: 3,
            name: "Prometheus",
            icon: prometheus,
        },
    ],
    [
        {
            id: 1,
            name: "Git",
            icon: git,
        },
        {
            id: 2,
            name: "Postman",
            icon: postman,
        },
        {
            id: 3,
            name: "Figma",
            icon: figma,
        },
    ],
];

export const skillsForHexagonWidth = [
    [
        {
            id: 1,
            name: "Java",
            icon: java,
        },
        {
            id: 2,
            name: "Python",
            icon: python,
        },
    ],
    [
        {
            id: 1,
            name: "JavaScript",
            icon: js,
        },
        {
            id: 2,
            name: "TypeScript",
            icon: ts,
        },
    ],
    [
        {
            id: 1,
            name: "HTML5",
            icon: html,
        },
        {
            id: 2,
            name: "CSS3",
            icon: css,
        },
    ],
    [
        {
            id: 1,
            name: "BootStrap",
            icon: bootstrap,
        },
        {
            id: 2,
            name: "TailwindCSS",
            icon: tailwind,
        },
    ],
    [
        {
            id: 1,
            name: "React",
            icon: react,
        },
        {
            id: 2,
            name: "Vue.js",
            icon: vue,
        },
        {
            id: 3,
            name: "Angular",
            icon: angular,
        },
    ],
    [
        {
            id: 1,
            name: "Node.js",
            icon: node,
        },
        {
            id: 2,
            name: "Spring Boot",
            icon: spring,
        },
    ],
    [
        {
            id: 1,
            name: "MySQL",
            icon: mysql,
        },
        {
            id: 2,
            name: "PostgreSQL",
            icon: postgresql,
        },
    ],
    [
        {
            id: 1,
            name: "Oracle Database",
            icon: oracle,
        },
        {
            id: 2,
            name: "MongoDB",
            icon: mongo,
        },
    ],
    [
        {
            id: 1,
            name: "Docker",
            icon: docker,
        },
        {
            id: 2,
            name: "Kubernetes",
            icon: k8s,
        },
    ],
    [
        {
            id: 1,
            name: "GitLab",
            icon: gitlab,
        },
        {
            id: 2,
            name: "Jenkins",
            icon: jenkins,
        },
        {
            id: 3,
            name: "Prometheus",
            icon: prometheus,
        },
    ],
    [
        {
            id: 1,
            name: "Git",
            icon: git,
        },
        {
            id: 2,
            name: "Postman",
            icon: postman,
        },
        {
            id: 3,
            name: "Figma",
            icon: figma,
        },
    ],
];

export const skillsFormd = [
    {
        id: 1,
        name: "Java",
        icon: java,
    },
    {
        id: 2,
        name: "Python",
        icon: python,
    },
    {
        id: 3,
        name: "JavaScript",
        icon: js,
    },
    {
        id: 4,
        name: "TypeScript",
        icon: ts,
    },
    {
        id: 5,
        name: "HTML5",
        icon: html,
    },
    {
        id: 6,
        name: "CSS3",
        icon: css,
    },
    {
        id: 7,
        name: "BootStrap",
        icon: bootstrap,
    },
    {
        id: 8,
        name: "TailwindCSS",
        icon: tailwind,
    },
    {
        id: 9,
        name: "React",
        icon: react,
    },
    {
        id: 10,
        name: "Vue.js",
        icon: vue,
    },
    {
        id: 11,
        name: "Angular",
        icon: angular,
    },
    {
        id: 12,
        name: "Node.js",
        icon: node,
    },
    {
        id: 13,
        name: "Spring Boot",
        icon: spring,
    },
    {
        id: 14,
        name: "MySQL",
        icon: mysql,
    },
    {
        id: 15,
        name: "PostgreSQL",
        icon: postgresql,
    },
    {
        id: 16,
        name: "Oracle Database",
        icon: oracle,
    },
    {
        id: 17,
        name: "MongoDB",
        icon: mongo,
    },
    {
        id: 18,
        name: "Docker",
        icon: docker,
    },
    {
        id: 19,
        name: "Kubernetes",
        icon: k8s,
    },
    {
        id: 20,
        name: "GitLab",
        icon: gitlab,
    },
    {
        id: 21,
        name: "Jenkins",
        icon: jenkins,
    },
    {
        id: 22,
        name: "Prometheus",
        icon: prometheus,
    },
    {
        id: 23,
        name: "Git",
        icon: git,
    },
    {
        id: 24,
        name: "Postman",
        icon: postman,
    },
    {
        id: 25,
        name: "Figma",
        icon: figma,
    },
];
