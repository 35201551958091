import MultipleHexagon from "./MultipleHexagon";
import Icon from "./utils/Icon";

const Services = ({ isSideBarDisplayed }) => {
    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="Services" />
            </div>
            <div className="my-12 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    Unlock Your Business Potential with Tailored Early Career{" "}
                    <span className="text-myBlue">Software Solutions</span>
                </p>
            </div>

            <div>
                <MultipleHexagon isSideBarDisplayed={isSideBarDisplayed} />
            </div>
        </div>
    );
};

export default Services;
