import React from "react";

import {
    FcAlphabeticalSortingAz,
    FcBusiness,
    FcCallback,
    FcDebt,
    FcDocument,
    FcGraduationCap,
    FcTodoList,
    FcWorkflow,
} from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";

const FileComponent = ({ name, whatIsClickedToBlueBachground }) => {
    return (
        <div
            className={`cursor-pointer px-[5%] ${
                whatIsClickedToBlueBachground === name
                    ? "bg-[#05395b] border-y border-[#1670AC]"
                    : "hover:bg-[#292d2e]"
            }`}
        >
            <div
                className={`${
                    name !== "Resume"
                        ? "border-l-[0.3px] border-gray-500 ml-[3%]"
                        : ""
                }`}
            >
                <div
                    className={`flex items-center gap-0.5 pl-[2.5%] border-l-[0.3px] border-gray-500 ${
                        name !== "Resume" ? "ml-[5%]" : "ml-[3%]"
                    }`}
                >
                    <IoIosArrowForward
                        size={13}
                        className="mt-[0.5px] opacity-0"
                    />

                    <div className="flex gap-1.5 items-center">
                        {name === "README.md" && <FcDebt />}
                        {name === "Services" && <FcTodoList />}
                        {name === "Work Experiences" && <FcBusiness />}
                        {name === "Education" && <FcGraduationCap />}
                        {name === "Skills" && <FcWorkflow />}
                        {name === "My Work" && <FcAlphabeticalSortingAz />}
                        {name === "Contact Me" && <FcCallback />}
                        {name === "Resume" && <FcDocument />}
                        <p>{name}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileComponent;
