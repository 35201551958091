import inpt from "../../../assets/inpt.png";
import go_digital from "../../../assets/go_ditigal.png";
import ilyess_2 from "../../../assets//ilyess_2.jpg";
import CardBackground from "../../../assets/CardBackground.jpg";

const INPTCard = () => {
    return (
        <div className="relative flex flex-col justify-center border-[0.1px] border-gray-600 rlg:w-[40vw] h-[34vh] rxl:h-[38vh] rounded-3xl">
            {/*Background image blured*/}
            <div
                className="absolute border-2 border-red-500 w-[99%] h-[99%] flex justify-center items-center blur rounded-3xl opacity-60"
                style={{
                    backgroundImage: `url(${CardBackground})`,
                }}
            ></div>

            {/*Header*/}
            <div className="absolute top-2 w-full flex justify-between px-[6%] py-2 text-[16px] rlg:text-[14.5px] xl:text-[18px]">
                <div>
                    <p className="font-montagu">IlYess' Card</p>
                </div>
                <div>
                    <p className="font-caveat">Survivor</p>
                </div>
            </div>

            {/*Body*/}
            <div className="flex justify-start items-center mb-2 px-[5%] gap-[5%] z-30">
                {/*Image*/}
                <div className="relative w-44 h-fit rounded-2xl">
                    <img
                        src={ilyess_2}
                        alt="IlYess"
                        className="relative w-28 h-28 rlg:w-24 rlg:h-24 lxl:w-28 lxl:h-28 rxl:w-32 rxl:h-32 rounded-2xl shadow-inner"
                    />
                    {/*Inner shadow of the image*/}
                    <div className="rounded-2xl shadow-inner shadow-black opacity-50 w-28 h-28 rlg:w-24 rlg:h-24 lxl:w-28 lxl:h-28 rxl:w-32 rxl:h-32 absolute top-0 left-0 z-30"></div>
                </div>

                {/*Texts*/}
                <div>
                    <div className="flex flex-col gap-1 text-[13px] rlg:text-[12px] lxl:text-[13px] xl:text-[16px] font-montserrat text-gray-300 text-nowrap">
                        <p>
                            Name :{" "}
                            <span className="font-medium font-montagu text-[14px] rlg:text-[13px] lxl:text-[14px] rxl:text-[18px] text-white">
                                NAJI Ilyass
                            </span>
                        </p>
                        <p>
                            Cursus :{" "}
                            <span className="font-medium font-montagu text-[14px] rlg:text-[13px] lxl:text-[14px] rxl:text-[18px] text-white">
                                Software Engineering (ASEDS)
                            </span>
                        </p>
                        <p>
                            Location :{" "}
                            <span className="font-medium font-montagu text-[14px] rlg:text-[13px] lxl:text-[14px] rxl:text-[18px] text-white">
                                Rabat, MOROCCO
                            </span>
                        </p>
                        <p>
                            Phone :{" "}
                            <span className="font-medium font-montagu text-[14px] rlg:text-[13px] lxl:text-[14px] rxl:text-[18px] text-white">
                                +212 660-021423
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            {/*Footer*/}
            <div className="flex items-center justify-end gap-[2%] absolute w-full bottom-3">
                <img
                    src={inpt}
                    alt="INPT"
                    className="w-30 rlg:w-24 xl:w-30 h-10"
                />
                <img
                    src={go_digital}
                    alt="INPT"
                    className="w-36 h-10 rlg:w-28 xl:w-36 rlg:h-8 xl:h-10"
                />
            </div>
        </div>
    );
};

export default INPTCard;
