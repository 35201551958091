import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { SiVsco } from "react-icons/si";
import {
    VscAdd,
    VscChevronDown,
    VscChevronUp,
    VscClose,
    VscEllipsis,
    VscSplitHorizontal,
    VscTerminalPowershell,
    VscTrash,
} from "react-icons/vsc";

const Terminal = ({
    setOpenTerminal,
    showDropdownElementsTerminal,
    toggleDropdownElementsTerminal,
}) => {
    const inputRef = useRef(null);
    const contentRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [iconSize] = useState(windowWidth < 500 ? 13 : 16);

    const [numberToHandleArrowUpDownClick, setNumberToHandleArrowUpDownClick] =
        useState(0);

    const [isPowerShellHovered, setIsPowerShellHovered] = useState(-1);
    const [whatPowerShellClicked, setWhatPowerShellClicked] = useState(0);

    //outputLines is on this format [{command, result}] and result on [indentation, value]
    const [inputValue, setInputValue] = useState("");
    const [outputLines, setOutputLines] = useState([]);

    const [heightTerminal, setHeightTerminal] = useState("30svh");
    const [heightContent, setHeightContent] = useState("24.6svh");

    const handleInput = () => {
        let newOutputLines = [...outputLines];
        const inputCommand = inputValue.trim();

        if (inputCommand === "help") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [[0, ""]],
                    [
                        [0, "help"],
                        [1, "Displays help for all commands"],
                    ],
                    [
                        [0, "clear"],
                        [1, "Clears the terminal screen"],
                    ],
                    [
                        [0, "whoami"],
                        [1, "Say my name"],
                    ],
                    [
                        [0, "email"],
                        [1, "Prints IlYess' email"],
                    ],
                    [
                        [0, "phone"],
                        [1, "Prints the phone number"],
                    ],
                    [
                        [0, "github"],
                        [1, "Prints GitHub username"],
                    ],
                    [
                        [0, "linkedin"],
                        [1, "Prints LinkedIn username"],
                    ],
                    [
                        [0, "jobs"],
                        [1, "Prints the current position"],
                    ],
                    [
                        [0, "age"],
                        [1, "Displays the age"],
                    ],
                    [
                        [0, "passwd"],
                        [1, "Displays my password"],
                    ],
                    [
                        [0, "top"],
                        [1, "Highlights the most project I'm proud of"],
                    ],
                    [
                        [0, "date"],
                        [1, "Shows the date of graduation from institute"],
                    ],
                    [
                        [0, "ls [option]"],
                        [1, "Unveils some of my skills"],
                        [
                            1,
                            "Use ls --help for more informations about options",
                        ],
                    ],
                    [
                        [0, "exit"],
                        [1, "Exits the terminal"],
                    ],
                ],
            });
        } else if (inputCommand === "clear") {
            newOutputLines = [];
        } else if (inputCommand === "whoami") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "NAJI Ilyass"]]],
            });
        } else if (inputCommand === "email") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "najiilyassoo@gmail.com"]]],
            });
        } else if (inputCommand === "phone") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "+212 660-021423"]]],
            });
        } else if (inputCommand === "github") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "NAJIilyass"]]],
            });
        } else if (inputCommand === "linkedin") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "naji-ilyass"]]],
            });
        } else if (inputCommand === "jobs") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "Research Assistant at Oracle"]]],
            });
        } else if (inputCommand === "age") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "21 years old"]]],
            });
        } else if (inputCommand === "passwd") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "********** :)"]]],
            });
        } else if (inputCommand === "top") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "EscolaLik"]]],
            });
        } else if (inputCommand === "date") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "July 2025"]]],
            });
        } else if (inputCommand === "exit") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, ""]]],
            });
            setTimeout(() => {
                setOpenTerminal(false);
            }, 200);
        } else if (inputCommand === "ls --help") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [
                        [0, "Available options:"],
                        [1, "-a : Displays all my skills"],
                        [1, "-l : Displays programming languages"],
                        [1, "-f : Displays frontend tools"],
                        [1, "-b : Displays backend tools"],
                        [1, "-db : Displays database management tools"],
                        [1, "-d : Displays devops tools"],
                        [1, "-t : Displays miscellaneous tools"],
                    ],
                ],
            });
        } else if (inputCommand === "ls -a") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [
                        [
                            0,
                            "Java    Python    JavaScript    TypeScript    HTML5    CSS3    BootStrap    TailwindCSS    React    Vue.js    Angular    Node.js    Spring Boot    MySQL    Oracle Database    PostgreSQL    MongoDB    Docker    Kubernetes    GitLab    Jenkins    Prometheus    Git    Postman    Figma",
                        ],
                    ],
                ],
            });
        } else if (inputCommand === "ls -l") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "Java    Python    JavaScript    TypeScript"]]],
            });
        } else if (inputCommand === "ls -f") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [
                        [
                            0,
                            "HTML5    CSS3    BootStrap    TailwindCSS    React    Vue.js    Angular",
                        ],
                    ],
                ],
            });
        } else if (inputCommand === "ls -b") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "Node.js    Spring Boot"]]],
            });
        } else if (inputCommand === "ls -db") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [[0, "MySQL    Oracle Database    PostgreSQL    MongoDB"]],
                ],
            });
        } else if (inputCommand === "ls -d") {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [
                        [
                            0,
                            "Docker    Kubernetes    GitLab    Jenkins    Prometheus",
                        ],
                    ],
                ],
            });
        } else if (inputCommand === "ls -t") {
            newOutputLines.push({
                command: inputValue,
                result: [[[0, "Git    Postman    Figma"]]],
            });
        } else {
            newOutputLines.push({
                command: inputValue,
                result: [
                    [
                        [
                            0,
                            'Invalid command. Try typing "help" for a list of commands',
                        ],
                    ],
                ],
            });
        }

        setInputValue("");
        setOutputLines(newOutputLines);
        setNumberToHandleArrowUpDownClick(newOutputLines.length - 1);
    };

    const handleClickArrow = (k, e) => {
        e.preventDefault();
        if (k === "Up") {
            if (numberToHandleArrowUpDownClick > 0) {
                setNumberToHandleArrowUpDownClick(
                    numberToHandleArrowUpDownClick - 1
                );
                if (!_.isEmpty(outputLines)) {
                    setInputValue(
                        outputLines[numberToHandleArrowUpDownClick].command
                    );
                }
            }
        } else {
            if (numberToHandleArrowUpDownClick < outputLines.length - 2) {
                setNumberToHandleArrowUpDownClick(
                    numberToHandleArrowUpDownClick + 1
                );
                if (!_.isEmpty(outputLines)) {
                    setInputValue(
                        outputLines[numberToHandleArrowUpDownClick + 2].command
                    );
                }
            } else {
                setInputValue("");
            }
        }
    };

    useEffect(() => {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }, [outputLines]);

    const detectOS = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.includes("win")) {
            return "Windows";
        } else if (platform.includes("mac")) {
            return "Mac";
        } else if (platform.includes("linux")) {
            return "Linux";
        } else {
            return "Unknown";
        }
    };

    const [os] = useState(detectOS());
    return (
        <div
            style={{ height: heightTerminal }}
            className="bg-menuBackground overflow-hidden"
        >
            {/*Top*/}
            <div className="flex justify-between items-center pr-2">
                {/*Top Left*/}
                <div className="flex gap-1 text-[#7e7e7e] font-[500] text-[10px] tracking-tight ml-2.5">
                    <div>
                        <p className="hidden footerJS:block px-2 py-2 hover:text-[#afafaf] cursor-pointer">
                            PROBLEMS
                        </p>
                    </div>

                    <div>
                        <p className="hidden footerCRLF:block px-2 py-2 hover:text-[#afafaf] cursor-pointer">
                            OUTPUT
                        </p>
                    </div>

                    <div className="hidden xs:block w-[100px]">
                        <p className="px-2 py-2 hover:text-[#afafaf] cursor-pointer text-nowrap">
                            DEBUG CONSOLE
                        </p>
                    </div>

                    <div>
                        <p className="text-[#afafaf] border-b border-b-myBlue px-2 py-2 cursor-pointer">
                            TERMINAL
                        </p>
                    </div>

                    {/*Dropdown for PROBLEMS, OUTPUT, DEBUG CONSOLE, PORTS*/}
                    <div
                        className="footerJS:hidden relative cursor-pointer flex items-center dropdown-container-terminal"
                        onClick={() => toggleDropdownElementsTerminal(0)}
                    >
                        <div className="py-[3px] px-2 hover:text-white">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsTerminal === 0 && (
                            <div className="absolute z-50 top-[30px] w-36 bg-bodyBackground border border-[#4f4f4f] rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Problems
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Output
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Debug console
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Ports
                                </p>
                            </div>
                        )}
                    </div>

                    {/*Dropdown for OUTPUT, DEBUG CONSOLE, PORTS*/}
                    <div
                        className="hidden footerJS:flex footerCRLF:hidden relative cursor-pointer items-center dropdown-container-terminal"
                        onClick={() => toggleDropdownElementsTerminal(0)}
                    >
                        <div className="py-[3px] px-2 hover:text-white">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsTerminal === 0 && (
                            <div className="absolute z-50 top-[30px] w-36 bg-bodyBackground border border-[#4f4f4f] rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Output
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Debug console
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Ports
                                </p>
                            </div>
                        )}
                    </div>

                    {/*Dropdown for DEBUG CONSOLE, PORTS*/}
                    <div
                        className="hidden footerCRLF:flex xs:hidden relative cursor-pointer items-center dropdown-container-terminal"
                        onClick={() => toggleDropdownElementsTerminal(1)}
                    >
                        <div className="py-[3px] px-2 hover:text-white">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsTerminal === 1 && (
                            <div className="absolute z-50 top-[30px] w-36 bg-bodyBackground border border-[#4f4f4f] rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Debug console
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Ports
                                </p>
                            </div>
                        )}
                    </div>

                    {/*Dropdown for PORTS*/}
                    <div
                        className="hidden xs:flex rxs:hidden relative cursor-pointer items-center dropdown-container-terminal"
                        onClick={() => toggleDropdownElementsTerminal(2)}
                    >
                        <div className="py-[3px] px-2 hover:text-white">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsTerminal === 2 && (
                            <div className="absolute z-50 top-[30px] w-36 bg-bodyBackground border border-[#4f4f4f] rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Ports
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="hidden rxs:block">
                        <p className="px-2 py-2 hover:text-[#afafaf] cursor-pointer">
                            PORTS
                        </p>
                    </div>
                </div>

                {/*Top Right*/}
                <div className="text-[#b0b0b0] flex justify-end items-center gap-2">
                    <div className="flex items-center cursor-pointer hover:bg-[#333335] rounded">
                        <div className="hover:bg-[#3c3b3b] px-0.5 py-0.5 h-full w-full rounded">
                            <VscAdd />
                        </div>
                        <div className="hover:bg-[#3c3b3b] px-0.5 py-0.5 h-full w-full rounded">
                            <VscChevronDown size={14} />
                        </div>
                    </div>

                    <div className="cursor-pointer hover:bg-[#333335] rounded px-0.5 py-0.5">
                        <VscEllipsis />
                    </div>

                    <div
                        onClick={() => {
                            if (heightTerminal === "93svh") {
                                setHeightTerminal("30svh");
                                setHeightContent("24.6svh");
                            } else {
                                setHeightTerminal("93svh");
                                setHeightContent("87.6svh");
                            }
                        }}
                        className="cursor-pointer hover:bg-[#333335] rounded px-0.5 py-0.5"
                    >
                        {heightTerminal === "93svh" && <VscChevronDown />}
                        {heightTerminal === "30svh" && <VscChevronUp />}
                    </div>

                    <div
                        onClick={() => {
                            setOpenTerminal(false);
                        }}
                        className="cursor-pointer hover:bg-[#333335] rounded px-0.5 py-0.5"
                    >
                        <VscClose />
                    </div>
                </div>
            </div>

            <div
                onClick={() => {
                    inputRef.current.focus();
                }}
                className="flex text-white mt-2"
            >
                {/*Content*/}
                <div
                    ref={contentRef}
                    style={{ height: heightContent }}
                    className="w-full overflow-y-scroll no-scroll text-gray-300 text-[10px] rsm:text-[12px] font-mono tracking-tight border-r-[0.3px] border-gray-700"
                >
                    <div className="flex flex-col gap-1 ml-[18.5px] mr-1 items-start mb-3">
                        <p>
                            Hello! Is there anything specific you'd like to know
                            about IlYess ?
                        </p>
                        <p>Type "help" to see all commands</p>
                    </div>

                    {/* Display previous terminal output */}
                    {outputLines.map((line, index) => (
                        <div key={index}>
                            <div className="flex relative gap-1 ml-1 items-start font-mono">
                                <div className="absolute left-0 right-0 top-0 flex gap-1 items-center">
                                    <div>
                                        <SiVsco
                                            size={10}
                                            className="mt-[1px]"
                                        />
                                    </div>
                                    <div>
                                        <p className="w-[240px] text-nowrap">
                                            PS C:\NAJI\IlYess\Servivor\Portfolio
                                            {">"}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-[100%]">
                                    <p className="text-[#dbe444] w-full">
                                        <span
                                            className={`break-all pl-[228px] ${
                                                os === "Mac" || os === "Linux"
                                                    ? "rsm:pl-[275px]"
                                                    : "rsm:pl-[250px]"
                                            }`}
                                        >
                                            {line.command}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {line.result.map((res, index1) => (
                                <div
                                    key={index1}
                                    className="ml-[14px] select-all"
                                >
                                    {res.map((one, index2) => (
                                        <div
                                            key={index2}
                                            className={`flex gap-1 ml-1 items-center mb-3 ${
                                                one[0] === 1
                                                    ? "ml-[40px] -mt-3"
                                                    : ""
                                            } ${
                                                one[1] ===
                                                'Invalid command. Try typing "help" for a list of commands'
                                                    ? "text-red-500"
                                                    : ""
                                            }`}
                                        >
                                            <p className="break-words w-full">
                                                {one[1].replace(/ /g, "\u00A0")}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}

                    {/*Input field*/}
                    <div className="flex gap-1 ml-1 items-center font-mono">
                        <div>
                            <SiVsco size={10} className="mt-[1px]" />
                        </div>
                        <div>
                            <p className="w-[194.2px] rsm:w-[240px] text-nowrap">
                                PS C:\NAJI\IlYess\Servivor\Portfolio
                                {">"}
                            </p>
                        </div>
                        <div
                            className={`text-[#dbe444] w-[78.55%] custom-input-marker mr-2 ml-4 ${
                                os === "Mac" || os === "Linux"
                                    ? ""
                                    : " md:-ml-2"
                            }`}
                        >
                            <input
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                autoFocus
                                ref={inputRef}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") handleInput();
                                    else if (e.key === "ArrowUp") {
                                        handleClickArrow("Up", e);
                                    } else if (e.key === "ArrowDown")
                                        handleClickArrow("Down", e);
                                }}
                                className="bg-transparent w-full focus:outline-none cursor-default"
                                spellCheck={false}
                                onMouseDown={(e) => e.preventDefault()}
                                onTouchStart={(e) => e.preventDefault()}
                            />
                        </div>
                    </div>
                </div>

                {/*Powershell*/}
                <div className="text-gray-300 text-[10px] rsm:text-[12px] w-[35px] lsm:w-[180px] overflow-hidden tracking-tight">
                    {/*Powershell id 0*/}
                    <div
                        onMouseEnter={() => setIsPowerShellHovered(0)}
                        onMouseLeave={() => setIsPowerShellHovered(-1)}
                        onClick={() => setWhatPowerShellClicked(0)}
                        className={`flex justify-between items-center w-full pl-2 pr-1 py-0.5 cursor-pointer ${
                            whatPowerShellClicked === 0
                                ? "bg-[#38373e] border-l-[0.3px] border-myBlue"
                                : "border-l-[0.3px] border-transparent"
                        }`}
                    >
                        <div className="flex gap-1 items-center">
                            <div>
                                <VscTerminalPowershell
                                    size={windowWidth > 610 ? 16 : 14}
                                    className="mb-1 mr-4 lsm:mr-0"
                                />
                            </div>
                            <div>
                                <p>powershell</p>
                            </div>
                        </div>

                        {isPowerShellHovered === 0 && (
                            <div className="flex justify-end gap-1 items-center">
                                <div>
                                    <VscSplitHorizontal
                                        size={windowWidth > 610 ? 16 : 14}
                                        className="mb-1 cursor-pointer hover:text-gray-200"
                                    />
                                </div>
                                <div>
                                    <VscTrash
                                        size={windowWidth > 610 ? 16 : 14}
                                        className="mb-1 cursor-pointer hover:text-gray-200"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terminal;
