import React, { useEffect, useRef, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";

import Home from "./pages/Home.js";
import Resume from "./pages/Resume.js";
import NoContent from "./pages/NoContent.js";

import NavBar from "./components/NavBar";
import MiniSideBar from "./components/MiniSideBar.js";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import Preloader from "../src/components/Pre";
import ContactMeGit from "./components/HomeComponents/ContactMeGit.js";
import ContactMeMail from "./components/HomeComponents/ContactMeMail.js";
import MiniNavBar from "./components/MiniNavBar.js";
import Terminal from "./components/Terminal.js";

import pdf from "./assets/Ilyass_NAJI_Resume.pdf";
import GitIgnore from "./pages/GitIgnore.js";

const App = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

//To maintain the use of useLocation()
const AppContent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [load, upadateLoad] = useState(true);

    //To show the animation on the start of the app
    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    const readmeRef = useRef(null);
    const servicesRef = useRef(null);
    const workExperienceRef = useRef(null);
    const educationRef = useRef(null);
    const skillsRef = useRef(null);
    const myWorkRef = useRef(null);
    const contactRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [isSideBarDisplayed, setIsSideBarDisplayed] = useState(
        window.innerWidth > 1024 ? true : false
    );
    const [openTerminal, setOpenTerminal] = useState(false);

    //MiniList contains the elements which are on the MiniNavBar
    //MiniActif is element clicked on the Navbar
    const [whatClicked, setWhatClicked] = useState("explorer");
    const [miniActif, setMiniActif] = useState(null);
    const [miniList, setMiniList] = useState([]);
    const [miniActifChanged, setMiniActifChanged] = useState(true);

    const handleMiniActifChanged = () => {
        setMiniActifChanged(!miniActifChanged);
    };

    const handleScrollToIDHome = (elt, sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleAddMiniListForPath = (elt, file) => {
        const filteredMiniList = miniList.filter((item) => item !== null);
        setMiniList(filteredMiniList);

        if (!miniList.includes(elt)) {
            setMiniList((prevList) => [...prevList, elt]);
        }
        setMiniActif(elt);
    };

    const handleAddMiniList = (elt, file) => {
        const filteredMiniList = miniList.filter((item) => item !== null);
        setMiniList(filteredMiniList);

        if (!miniList.includes(elt)) {
            setMiniList((prevList) => [...prevList, elt]);
        }
        setMiniActif(elt);
        handleMiniActifChanged();
    };

    const handleDeleteMiniList = (elt) => {
        let _id = null;
        for (let i = 0; i < miniList.length; i++) {
            if (miniList[i] === elt) {
                _id = i;
                break;
            }
        }

        if (_id !== null) {
            miniList.splice(_id, 1);
        }

        if (miniList.length !== 0) {
            if (_id === 0) {
                setMiniActif(miniList[_id]);
            } else {
                setMiniActif(miniList[_id - 1]);
            }
        } else setMiniActif(null);
        handleMiniActifChanged();
    };

    const handleDownloadResume = () => {
        const link = document.createElement("a");
        link.href = pdf;
        link.download = "Ilyass_NAJI_Resume.pdf";
        link.click();
    };

    const [showDropdownElementsNavbar, setShowDropdownElementsNavbar] =
        useState(-1);
    const [showDropdownElementsTerminal, setShowDropdownElementsTerminal] =
        useState(-1);

    const toggleDropdownElementsNavbar = (k) => {
        if (showDropdownElementsNavbar === k) setShowDropdownElementsNavbar(-1);
        else setShowDropdownElementsNavbar(k);
    };
    const toggleDropdownElementsTerminal = (k) => {
        if (showDropdownElementsTerminal === k)
            setShowDropdownElementsTerminal(-1);
        else setShowDropdownElementsTerminal(k);
    };

    const handleOutsideClickDropdownElements = (event) => {
        if (!event.target.closest(".dropdown-container-navbar")) {
            setShowDropdownElementsNavbar(-1);
        }
        if (!event.target.closest(".dropdown-container-terminal")) {
            setShowDropdownElementsTerminal(-1);
        }
    };

    // Handle shortcuts on the App
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                (event.ctrlKey || event.metaKey) &&
                (event.key === "y" || event.key === "Y")
            ) {
                setOpenTerminal(true);
            } else if (
                (event.ctrlKey || event.metaKey) &&
                (event.key === "b" || event.key === "B")
            ) {
                navigate("/about-me");
            } else if (
                (event.ctrlKey || event.metaKey) &&
                (event.key === "q" || event.key === "Q")
            ) {
                navigate("/resume");
            } else if (
                (event.ctrlKey || event.metaKey) &&
                event.shiftKey &&
                (event.key === "z" || event.key === "Z")
            ) {
                handleDownloadResume();
            } else if (
                (event.ctrlKey || event.metaKey) &&
                (event.key === "i" || event.key === "I")
            ) {
                navigate("/gitignore");
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    //Path change => Change the miniActif and miniList
    useEffect(() => {
        const path = location.pathname;
        let miniActifValue = "";

        switch (path) {
            case "/resume":
                miniActifValue = "Resume.js";
                break;
            case "/about-me":
                miniActifValue = "about_me.js";
                break;
            case "/gitignore":
                miniActifValue = ".gitignore";
                break;
            default:
                miniActifValue = null;
        }

        handleAddMiniListForPath(miniActifValue);
    }, [location.pathname]);

    //miniActif change => Change the path
    useEffect(() => {
        if (miniActif === "Resume.js") {
            navigate("/resume");
        } else if (miniActif === "about_me.js") {
            navigate("/about-me");
        } else if (miniActif === ".gitignore") {
            navigate("/gitignore");
        } else {
            navigate("/");
        }
    }, [miniActifChanged]);

    const detectOS = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.includes("win")) {
            return "Windows";
        } else if (platform.includes("mac")) {
            return "Mac";
        } else if (platform.includes("linux")) {
            return "Linux";
        } else {
            return "Unknown";
        }
    };

    const [os] = useState(detectOS());
    return (
        <div
            className="min-w-[300px] max-h-[100svh] min-h-[100svh] overflow-hidden no-scroll"
            onClick={handleOutsideClickDropdownElements}
        >
            <NavBar
                setOpenTerminal={setOpenTerminal}
                showDropdownElementsNavbar={showDropdownElementsNavbar}
                toggleDropdownElementsNavbar={toggleDropdownElementsNavbar}
                handleMiniActifChanged={handleMiniActifChanged}
                setMiniActif={setMiniActif}
                setMiniList={setMiniList}
                setIsSideBarDisplayed={setIsSideBarDisplayed}
            />
            <MiniNavBar
                miniActif={miniActif}
                setMiniActif={setMiniActif}
                handleMiniActifChanged={handleMiniActifChanged}
                miniList={miniList}
                handleDeleteMiniList={handleDeleteMiniList}
                isSideBarDisplayed={isSideBarDisplayed}
            />

            <div
                style={{ height: "calc(100svh - 52.2px)" }}
                className="bg-bodyBackground flex overflow-hidden select-none"
            >
                <MiniSideBar
                    whatClicked={whatClicked}
                    setWhatClicked={setWhatClicked}
                    isSideBarDisplayed={isSideBarDisplayed}
                    setIsSideBarDisplayed={setIsSideBarDisplayed}
                />
                {isSideBarDisplayed && (
                    <SideBar
                        whatClicked={whatClicked}
                        handleAddMiniList={handleAddMiniList}
                        handleScrollToIDHome={(elt, sectionRef) =>
                            handleScrollToIDHome(elt, sectionRef)
                        }
                        readmeRef={readmeRef}
                        servicesRef={servicesRef}
                        workExperienceRef={workExperienceRef}
                        educationRef={educationRef}
                        skillsRef={skillsRef}
                        myWorkRef={myWorkRef}
                        contactRef={contactRef}
                        miniActif={miniActif}
                    />
                )}

                <div className="w-full overflow-y-scroll no-scroll overflow-x-hidden">
                    {/* Conditionally render Preloader */}
                    {load && <Preloader load={load} />}
                    <div
                        className={`${
                            windowWidth > 1000 && isSideBarDisplayed
                                ? "pr-[2.5%]"
                                : "pr-[5%] footerUTF:pr-[3.5%] h-full lg:pr-[2.5%] pl-[4%] rmd:pl-0"
                        }`}
                    >
                        <Routes>
                            <Route exact path="/" element={<NoContent />} />
                            <Route
                                path="/about-me"
                                element={
                                    <Home
                                        readmeRef={readmeRef}
                                        servicesRef={servicesRef}
                                        workExperienceRef={workExperienceRef}
                                        educationRef={educationRef}
                                        skillsRef={skillsRef}
                                        myWorkRef={myWorkRef}
                                        contactRef={contactRef}
                                        isSideBarDisplayed={isSideBarDisplayed}
                                    />
                                }
                            />
                            <Route path="/resume" element={<Resume />} />
                            <Route path="/gitignore" element={<GitIgnore />} />
                        </Routes>
                    </div>

                    {!load &&
                        os !== "Mac" &&
                        os !== "Linux" &&
                        miniActif !== ".gitignore" && (
                            <div
                                className={`hidden llg:block  absolute bottom-[21.5rem] lmd:bottom-[23.3rem] text-[14px] lmd:text-[16px] ${
                                    windowWidth > 1000 && isSideBarDisplayed
                                        ? "right-[2.5%]"
                                        : "right-[5%] footerUTF:right-[3.5%] lg:right-[2.5%]"
                                }`}
                            >
                                <ContactMeMail />
                            </div>
                        )}

                    {!load &&
                        os !== "Mac" &&
                        os !== "Linux" &&
                        miniActif !== ".gitignore" && (
                            <div
                                className={`hidden llg:block absolute bottom-[11.8rem] lmd:bottom-[12.2rem] ${
                                    windowWidth > 1000 && isSideBarDisplayed
                                        ? "left-[20%] llg:left-[20%] lxl:left-[15%]"
                                        : "left-[16%] lsm:left-[10%] lg:left-[6%]"
                                }`}
                            >
                                <ContactMeGit />
                            </div>
                        )}

                    {!load && openTerminal && (
                        <div
                            style={{
                                position: "absolute",
                                right: "0px",
                                bottom: "16.4px",
                                left:
                                    windowWidth > 1000 && isSideBarDisplayed
                                        ? "190px"
                                        : "40px",
                            }}
                            className="z-40 overflow-hidden"
                        >
                            <Terminal
                                setOpenTerminal={setOpenTerminal}
                                showDropdownElementsTerminal={
                                    showDropdownElementsTerminal
                                }
                                toggleDropdownElementsTerminal={
                                    toggleDropdownElementsTerminal
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default App;
