import React from "react";
import gitignore from "../assets/jsons/gitignore";

const GitIgnore = () => {
    let counter = 1;

    return (
        <div className="mt-10 -ml-[5%] rmd:ml-0 font-mono text-[10px] xs:text-[13px] text-nowrap overflow-x-scroll no-scroll">
            <div>
                {gitignore.map((line) => (
                    <div key={line.id}>
                        {line.comment && (
                            <div className="flex justify-start gap-4">
                                <div className="text-right text-[#6e7582] w-[32px]">
                                    <p className="w-[32px]">{counter++}</p>
                                </div>

                                <p className="text-[#6f9553]"># {line.text}</p>
                            </div>
                        )}
                        {!line.comment && (
                            <div>
                                <div className="flex justify-start gap-4">
                                    <div className="text-right text-[#6e7582] w-[2rem]">
                                        <p className="w-[32px]">{counter++}</p>
                                    </div>
                                    <p className="text-[#ceccc6]">
                                        {line.text}
                                    </p>
                                </div>

                                <div className="flex justify-start gap-4">
                                    <div className="text-right text-[#6e7582] w-[2rem]">
                                        <p className="w-[32px]">{counter++}</p>
                                    </div>
                                    <p className="text-transparent">IlYess</p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GitIgnore;
