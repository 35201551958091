import { FaRegLightbulb } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { LuCode2 } from "react-icons/lu";
import { MdDevices } from "react-icons/md";

const ServiceHexagon = ({ title, desc }) => {
    return (
        <div className="hexagon-class relative w-[200px] h-[175px] rsm:w-[339px] rsm:h-[297px] z-10 font-montserrat select-none cursor-default">
            <div className="flex flex-col gap-[1.5vh] rsm:gap-[2.5vh] justify-center items-center">
                {/*Icon > rsm */}
                <div className="text-myBlue hidden rsm:block font-bold mt-[10%]">
                    {title === "Software Engineering" && (
                        <FiSettings size={58} />
                    )}
                    {title === "Web Development" && <LuCode2 size={58} />}
                    {title === "Problem Solving" && (
                        <FaRegLightbulb size={58} />
                    )}
                    {title === "UI/UX Design" && <MdDevices size={58} />}
                </div>

                {/*Icon < rsm */}
                <div className="text-myBlue rsm:hidden font-bold mt-[5%]">
                    {title === "Software Engineering" && (
                        <FiSettings size={34} />
                    )}
                    {title === "Web Development" && <LuCode2 size={34} />}
                    {title === "Problem Solving" && (
                        <FaRegLightbulb size={34} />
                    )}
                    {title === "UI/UX Design" && <MdDevices size={34} />}
                </div>

                <div className="flex flex-col gap-2 rsm:gap-3">
                    <div>
                        <p className="text-[13px] rsm:text-xl font-bold text-center">
                            {title}
                        </p>
                    </div>
                    <div className="w-[176px] rsm:w-[300px]">
                        <p className="text-[10px] rsm:text-base text-center opacity-70">
                            {desc}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceHexagon;
