const Command = ({ name, listButtons }) => {
    return (
        <div className="grid grid-cols-2 gap-2 items-end text-[9px] rxs:text-[12px]">
            <p className="text-[#656565] font-[500] opacity-85 tracking-wide text-right">
                {name}
            </p>
            <div className="flex gap-2 items-end">
                {listButtons.map((butt, index) => (
                    <div key={index} className="flex gap-2 items-end">
                        <div className="bg-[#2d2d2d] text-[#b1b1b1] w-fit rounded font-source-sans-3 px-1.5 pt-[1px] mb-[1px] text-[7.5px] rxs:text-[10px] border-b border-black shadow-sm shadow-[#2d2d2d]">
                            <p>{butt}</p>
                        </div>
                        {listButtons.indexOf(butt) !==
                            listButtons.length - 1 && (
                            <div>
                                <p className="text-[#8e8e8e]">+</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Command;
