import { useEffect, useState } from "react";
import pdf from "../assets/Ilyass_NAJI_Resume.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { CiSaveDown1 } from "react-icons/ci";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Resume = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDownload = () => {
        fetch(pdf).then((response) => {
            const filename = "Ilyass_NAJI_Resume.pdf";
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
        });
    };

    return (
        <div className="h-full flex flex-col justify-center items-center xs:block my-[10vh]">
            <div className=" text-white text-center">
                <Document file={pdf} className="flex justify-center">
                    <Page
                        pageNumber={1}
                        scale={
                            windowWidth > 1166
                                ? 1.6
                                : windowWidth > 1000
                                ? 1.2
                                : windowWidth > 800
                                ? 1
                                : windowWidth > 650
                                ? 0.8
                                : 0.54
                        }
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>
            </div>

            {/*Download*/}
            <div className="flex justify-center mt-[10vh]">
                <button
                    className="hidden xs:flex justify-center items-center gap-3 px-12 py-2 rounded-lg font-montserrat font-semibold text-white bg-myBlue scale-75 lsm:scale-90 lmd:scale-100"
                    onClick={handleDownload}
                >
                    <CiSaveDown1 size={25} />
                    <p>Download CV</p>
                </button>
            </div>

            {/*Download < xs*/}
            <div className="flex justify-center">
                <button
                    className="xs:hidden flex flex-col justify-center items-center px-6 py-2 rounded-lg text-sm font-montserrat font-semibold text-white bg-myBlue scale-75"
                    onClick={handleDownload}
                >
                    <CiSaveDown1 size={25} />
                    <p>Download</p>
                    <p>CV</p>
                </button>
            </div>
            <div className="h-24"></div>
        </div>
    );
};

export default Resume;
