import MultipleSkills from "./MultipleSkills";
import Cube from "./utils/Cube";
import Icon from "./utils/Icon";

const Skills = () => {
    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="Skills" />
            </div>
            <div className="my-12 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    Embracing <span className="text-myBlue">Innovation</span>{" "}
                    and Diverse{" "}
                    <span className="text-myBlue">Technologies</span>
                </p>
            </div>

            <div className="pb-[8%] pt-[4%] scale-75 md:scale-100 pr-[1.5rem] md:pr-[7.7rem] hexagon:pr-[8.2rem] flex justify-center">
                <Cube />
            </div>
            <div className="py-12 pr-[4%]">
                <MultipleSkills />
            </div>
        </div>
    );
};

export default Skills;
