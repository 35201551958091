import { FaLocationDot } from "react-icons/fa6";
import { FiGithub, FiLinkedin } from "react-icons/fi";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";

const OneContact = ({ name, info }) => {
    const handleClick = () => {
        if (name === "EMAIL") {
            window.location.href = "mailto:najiilyassoo@gmail.com";
        } else if (name === "LOCATION") {
            window.open(
                "https://www.google.com/maps/place/Madinat+Al+Irfane,+Rabat/@33.9794358,-6.8864362,14z/data=!3m1!4b1!4m6!3m5!1s0xda76cc369cfaf7b:0x11a5aeee401f8bfd!8m2!3d33.98136!4d-6.8645856!16s%2Fg%2F1tzl_9vd?entry=ttu",
                "_blank"
            );
        } else if (name === "PHONE") {
            window.open(
                "https://api.whatsapp.com/send/?phone=212660021423&text&type=phone_number&app_absent=0",
                "_blank"
            );
        } else if (name === "LINKEDIN") {
            window.open("https://www.linkedin.com/in/naji-ilyass/", "_blank");
        } else if (name === "GITHUB") {
            window.open("https://github.com/NAJIilyass/", "_blank");
        }
    };

    return (
        <div
            onClick={handleClick}
            className="bg-[#424242] cursor-pointer text-center font-montserrat w-[13.125rem] h-[9.52rem] xs:w-[16.125rem] xs:h-[11.70rem] rlg:w-[19.125rem] rlg:h-[13.875rem] overflow-hidden rounded-2xl shadow-lg shadow-black hover:opacity-60 transition-opacity duration-300"
        >
            <div className="flex flex-col items-center pt-[6%] gap-3 h-full">
                {/*>rlg*/}
                <div className="text-myBlue hidden rlg:flex justify-center items-center mb-3">
                    {name === "EMAIL" && <IoMdMail size={75} />}
                    {name === "LOCATION" && <FaLocationDot size={75} />}
                    {name === "PHONE" && <IoCall size={75} />}
                    {name === "LINKEDIN" && <FiLinkedin size={75} />}
                    {name === "GITHUB" && <FiGithub size={75} />}
                </div>

                {/*<rlg*/}
                <div className="text-myBlue hidden xs:flex rlg:hidden justify-center items-center mb-3">
                    {name === "EMAIL" && <IoMdMail size={63} />}
                    {name === "LOCATION" && <FaLocationDot size={63} />}
                    {name === "PHONE" && <IoCall size={63} />}
                    {name === "LINKEDIN" && <FiLinkedin size={63} />}
                    {name === "GITHUB" && <FiGithub size={63} />}
                </div>

                {/*<xs*/}
                <div className="text-myBlue xs:hidden flex justify-center items-center mb-3">
                    {name === "EMAIL" && <IoMdMail size={40} />}
                    {name === "LOCATION" && <FaLocationDot size={40} />}
                    {name === "PHONE" && <IoCall size={40} />}
                    {name === "LINKEDIN" && <FiLinkedin size={40} />}
                    {name === "GITHUB" && <FiGithub size={40} />}
                </div>

                <div className="flex flex-col gap-2">
                    <div className="text-[15px] xs:text-lg rlg:text-2xl font-semibold tracking-widest ">
                        {name}
                    </div>
                    <div className="text-myBlue text-[13.3px] xs:text-base rlg:text-lg tracking-widest hover:underline decoration-1 underline-offset-2">
                        {info}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OneContact;
