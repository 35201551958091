import { LuUserCircle2 } from "react-icons/lu";
import { MdOutlineSchool, MdWorkOutline } from "react-icons/md";
import { LuListTodo } from "react-icons/lu";
import { RiFlowChart } from "react-icons/ri";
import { ImSortAlphaAsc } from "react-icons/im";
import { IoCallOutline } from "react-icons/io5";
import { useEffect, useState } from "react";

const Icon = ({ name }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="relative">
            <div className="absolute -top-0.5 left-0 text-white w-fit h-fit rounded-full">
                <div className="relative z-30">
                    {name === "README.md" && (
                        <LuUserCircle2
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                    {name === "Services" && (
                        <LuListTodo
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                    {name === "Work Experiences" && (
                        <MdWorkOutline
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                    {name === "Education" && (
                        <MdOutlineSchool
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                    {name === "Skills" && (
                        <RiFlowChart
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                    {name === "My Work" && (
                        <ImSortAlphaAsc
                            size={
                                windowWidth > 830
                                    ? 30
                                    : windowWidth > 530
                                    ? 26
                                    : 22
                            }
                        />
                    )}
                    {name === "Contact Me" && (
                        <IoCallOutline
                            size={
                                windowWidth > 830
                                    ? 35
                                    : windowWidth > 530
                                    ? 30
                                    : 26
                            }
                        />
                    )}
                </div>
                <div className="bg-myBlue blur-md w-6 lmd:w-8 h-6 lmd:h-8 absolute top-0.5 lmd:top-0 left-0.5 lmd:left-0"></div>
            </div>
            <div className="pl-9 xs:pl-12 xs:pt-[1.5px] lmd:pt-0">
                <p className="text-white text-[13px] lmd:mt-0 xs:text-[17px] lmd:text-xl">
                    {name}
                </p>
            </div>
        </div>
    );
};

export default Icon;
