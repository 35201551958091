import services from "../../assets/jsons/services";
import ServiceHexagon from "./utils/ServiceHexagon";

const MultipleHexagon = ({ isSideBarDisplayed }) => {
    return (
        <div className="flex items-center hexagon:items-start flex-col gap-[22.5px] lxl:pl-[4%] rxl:pl-[6%]">
            <div
                className={`flex flex-col llg:flex-row gap-[22.5px] ${
                    isSideBarDisplayed ? "" : "hexagon:gap-[46px]"
                }`}
            >
                {services[0].map((serv) => (
                    <div key={serv.id}>
                        <ServiceHexagon title={serv.title} desc={serv.desc} />
                    </div>
                ))}
            </div>
            <div
                className={`flex flex-col llg:flex-row gap-[22.5px] ${
                    isSideBarDisplayed
                        ? ""
                        : "hexagon:gap-[46px] hexagon:ml-[192.25px] hexagon:-mt-[60px]"
                }`}
            >
                {services[1].map((serv) => (
                    <div key={serv.id}>
                        <ServiceHexagon title={serv.title} desc={serv.desc} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultipleHexagon;
