import GitCalendar from "./GitCalendar";
import Projects from "./Projects";
import Icon from "./utils/Icon";

const MyWork = () => {
    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="My Work" />
            </div>
            <div className="mt-12 mb-8 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    Crafting High-Quality{" "}
                    <span className="text-myBlue">Solutions</span> from Concept
                    to Reality{" "}
                </p>
            </div>

            <div>
                <Projects />
            </div>

            <div className="mt-[20vh] xs:mt-8">
                <GitCalendar />
            </div>
        </div>
    );
};

export default MyWork;
