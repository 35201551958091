import {
    VscBellDot,
    VscBroadcast,
    VscCheck,
    VscError,
    VscJson,
    VscRadioTower,
    VscRemote,
    VscSourceControl,
    VscSync,
    VscWarning,
} from "react-icons/vsc";

const Footer = () => {
    return (
        <div className="flex z-50 justify-between min-h-[17.5px] max-h-[17.5px] h-[17.5px] text-[9.9px] text-nowrap bg-menuBackground text-gray-300 border-b-[0.3px] border-gray-700 select-none">
            {/*Left div*/}
            <div className="flex h-full gap-1">
                <div className="bg-[#0178d4] w-[27px] h-full flex items-center justify-center text-white cursor-pointer hover:opacity-80 hover:bg-blue-400">
                    <VscRemote size={12} />
                </div>

                <div className="flex h-full items-center px-0.5 hover:bg-[#333335] cursor-pointer">
                    <VscSourceControl size={12} />
                    <p className="mx-0.5">master*</p>
                    <VscSync size={12.5} />
                </div>

                <div className="flex h-full items-center px-0.5 gap-0.5 hover:bg-[#333335] cursor-pointer">
                    <VscError size={13} />
                    <p className="mt-[4%]">0</p>
                    <VscWarning size={13} />
                    <p className="mt-[4%]">0</p>
                </div>

                <div className="flex h-full items-center px-0.5 gap-0.5 hover:bg-[#333335] cursor-pointer">
                    <VscRadioTower size={13} />
                    <p className="mt-[4%]">0</p>
                </div>
            </div>

            {/*Right div*/}
            <div className="flex h-full gap-1 overflow-hidden w-full justify-end">
                <div className="hidden footerSpace:flex items-center px-[0.4%] -mr-0.5 hover:bg-[#333335] cursor-pointer">
                    <p>Spaces: 4</p>
                </div>
                <div className="hidden footerUTF:flex items-center px-[0.4%] hover:bg-[#333335] cursor-pointer">
                    <p>UTF-8</p>
                </div>
                <div className="hidden footerCRLF:flex items-center px-[0.4%] hover:bg-[#333335] cursor-pointer">
                    <p>CRLF</p>
                </div>
                <div className="hidden footerJS:flex items-center justify-center h-full gap-0.5 px-[0.2%] hover:bg-[#333335] cursor-pointer">
                    <VscJson size={12} />
                    <p>JavaScript</p>
                </div>
                <div className="flex items-center justify-center h-full gap-0.5 px-[0.4%] hover:bg-[#333335] cursor-pointer">
                    <VscBroadcast size={12} />
                    <p>Go Live</p>
                </div>
                <div className="flex items-center justify-center h-full gap-0.5 px-[0.4%] hover:bg-[#333335] cursor-pointer">
                    <VscCheck size={12} />
                    <p>Prettier</p>
                </div>
                <div className="h-full flex items-center px-[0.4%] mr-1.5 hover:bg-[#333335] cursor-pointer">
                    <VscBellDot size={12} />
                </div>
            </div>
        </div>
    );
};

export default Footer;
