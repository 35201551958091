const ContactMeMail = () => {
    return (
        <div className="absolute text-white w-full h-full justify-between transform rotate-90 origin-left flex gap-10 tracking-widest">
            <a
                href="mailto:najiilyassoo@gmail.com"
                className="font-montserrat cursor-pointer hover:text-myBlue hover:-translate-x-0.5 hover:transition duration-500 ease-ease-in-out"
            >
                najiilyassoo@gmail.com
            </a>
            <div className="-mt-[7px]">
                <p className="tracking-tight font-extrabold text-[16px]">
                    ______________
                </p>
            </div>
        </div>
    );
};

export default ContactMeMail;
