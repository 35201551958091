import morocco from "../assets/morocco.webp";
import Command from "../components/NoContentComponents/Command";

const NoContent = () => {
    return (
        <div className="text-black ml-[6%] xs:ml-0 border border-transparent">
            {/*Name and flag*/}
            <div className="mt-[12vh] xs:mt-[26vh] flex flex-col xs:flex-row mr-[10%] xs:mr-0 xs:justify-center items-center gap-4 text-[40px] rxs:text-[50px] lsm:text-[70px] text-[#161616] font-mono tracking-wider">
                <p className="font-jaini">NAJI IlYess </p>
                <img
                    src={morocco}
                    alt="🇲🇦"
                    className="w-[80px] footerSpace:w-[110px] lmd:w-[140px] h-[45px] footerSpace:h-[62px] lmd:h-[80px] mt-[1%] ml-2"
                    style={{ filter: "brightness(0.3)" }}
                />
                <p>
                    <span style={{ filter: "brightness(0.2)" }}> 👋🏻</span>
                </p>
            </div>

            {/*Commandes*/}
            <div className="flex ml-4 xs:ml-0 flex-col items-center gap-4 mt-[7vh] mr-[11vw] xs:mr-4">
                <Command name={"Open Terminal"} listButtons={["Ctrl", "Y"]} />
                <Command
                    name={"Go To about_me.js"}
                    listButtons={["Ctrl", "B"]}
                />
                <Command
                    name={"Go To .gitignore"}
                    listButtons={["Ctrl", "I"]}
                />
                <Command name={"Go To Resume.js"} listButtons={["Ctrl", "Q"]} />
                <Command
                    name={"Download My Resume"}
                    listButtons={["Ctrl", "Shift", "Z"]}
                />
            </div>
        </div>
    );
};

export default NoContent;
