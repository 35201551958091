const OneSkill = ({ name, icon }) => {
    return (
        <div className="skill scroll-auto relative w-[183px] h-[150px] rounded-2xl flex justify-center items-center overflow-hidden scale-75 md:scale-100">
            <span className="absolute inset-[5px] bg-[#0c1022] rounded-[16px] z-10"></span>
            <div className="z-30 flex flex-col items-center gap-[1vh]">
                <div className="w-16 h-16 flex justify-center items-center">
                    <img
                        src={icon}
                        alt="icon"
                        className={`${
                            name === "MongoDB" || name === "Figma"
                                ? "scale-75"
                                : ""
                        }`}
                    />
                </div>
                <div>
                    <p className="font-semibold font-montserrat">{name}</p>
                </div>
            </div>
        </div>
    );
};

export default OneSkill;
