import OneProject from "./OneProject";
import { projects } from "../../assets/jsons/projects";

const Projects = () => {
    return (
        <div className="container">
            <ul id="cards">
                <li className="card" id="card1">
                    <OneProject
                        name={projects[0].name}
                        tools={projects[0].tools}
                        description={projects[0].description}
                    />
                </li>
                <li className="card" id="card2">
                    <OneProject
                        name={projects[1].name}
                        tools={projects[1].tools}
                        description={projects[1].description}
                    />
                </li>
                <li className="card" id="card3">
                    <OneProject
                        name={projects[2].name}
                        tools={projects[2].tools}
                        description={projects[2].description}
                    />
                </li>
                <li className="card" id="card4">
                    <OneProject
                        name={projects[3].name}
                        tools={projects[3].tools}
                        description={projects[3].description}
                    />
                </li>
            </ul>
        </div>
    );
};

export default Projects;
