export const projects = [
    {
        id: 1,
        name: "EnvisionLive",
        description:
            "EnvisionLive is a live streaming platform designed to provide users with a seamless experience for broadcasting and viewing live events. The platform offers features such as real-time chat, viewer interaction, and customizable streaming options, enhancing engagement and interaction during live broadcasts.",
        tools: [
            "Angular",
            "Spring Boot",
            "Tailwind CSS",
            "Web Socket",
            "Figma",
        ],
    },
    {
        id: 2,
        name: "Social Network API",
        description:
            "The Social Network API project focuses on building a robust RESTful API to power a social networking application. It includes features such as user authentication, post creation, commenting, and liking functionality, catering to the needs of a growing social network platform while ensuring scalability, security, and efficiency in data handling.",
        tools: ["Spring Boot", "PostgreSQL", "Swagger", "Postman"],
    },
    {
        id: 3,
        name: "Angular-Spring-Dockerized-App",
        description:
            "Angular-Spring-Dockerized-App is a containerized 3-tier web application aimed at simplifying deployment and scalability. It utilizes containerization technology to package the frontend, backend, and database components into separate containers, streamlining the deployment process and ensuring scalability as the user base grows.",
        tools: ["Docker", "Kubernetes"],
    },
    {
        id: 4,
        name: "INELearn",
        description:
            "INELearn is a platform dedicated to sharing educational resources in a user-friendly and accessible manner. It provides educators with a platform to upload, organize, and share educational materials with students, facilitating seamless interaction between teachers and learners.",
        tools: ["React", "Bootstrap", "Figma"],
    },
];
