import React, { useEffect, useState } from "react";
import jn from "../assets/jn.png";
import { LiaSearchSolid } from "react-icons/lia";
import {
    VscArrowLeft,
    VscArrowRight,
    VscChromeClose,
    VscChromeMinimize,
    VscChromeRestore,
    VscEllipsis,
    VscLayout,
    VscLayoutPanelOff,
    VscLayoutSidebarLeft,
    VscLayoutSidebarRightOff,
} from "react-icons/vsc";

const NavBar = ({
    setOpenTerminal,
    showDropdownElementsNavbar,
    toggleDropdownElementsNavbar,
    setMiniList,
    setMiniActif,
    handleMiniActifChanged,
    setIsSideBarDisplayed,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [iconSize] = useState(windowWidth < 500 ? 13 : 16);

    return (
        <div className="grid overflow-hidden grid-cols-3 items-center h-[35px] bg-menuBackground text-gray-300 text-xs font-[500] border-b-[0.3px] border-gray-700 select-none">
            <div className="flex items-center w-[96%]">
                {/* Logo */}
                <div className="min-w-[40px] w-[40px] h-[34px] flex justify-center items-center">
                    <img
                        src={jn}
                        alt="JN"
                        onClick={() => {
                            setIsSideBarDisplayed(false);
                            handleMiniActifChanged();
                            setMiniActif(null);
                            setMiniList([]);
                        }}
                        className="scale-50 cursor-pointer"
                    />
                </div>

                {/*File - Help*/}
                <div className="flex items-center ml-[0.2%]">
                    {/*Dropdown for All*/}
                    <div
                        className="rmd:hidden rounded-md hover:bg-[#2d2f2e] cursor-default ml-[1.5%] mr-[0.2%] dropdown-container-navbar"
                        onClick={() => toggleDropdownElementsNavbar(0)}
                    >
                        <div className="py-[3px] px-2">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsNavbar === 0 && (
                            <div className="absolute z-50 mt-2 w-36 bg-bodyBackground rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    File
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Edit
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Selection
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    View
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Go
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Run
                                </p>
                                <p
                                    onClick={() => setOpenTerminal(true)}
                                    className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer"
                                >
                                    Terminal
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Help
                                </p>
                            </div>
                        )}
                    </div>

                    <p className="py-[3px] px-2 mr-[0.2%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden rmd:block">
                        File
                    </p>

                    <p className="py-[3px] px-2 mr-[0.5%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden rmd:block">
                        Edit
                    </p>

                    <p className="py-[3px] px-2 mr-[0.5%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden rmd:block">
                        Selection
                    </p>

                    {/*Dropdown for View, Go, Run, Terminal, Help*/}
                    <div
                        className="hidden rmd:block lmd:hidden rounded-md hover:bg-[#2d2f2e] cursor-default ml-[1.5%] mr-[0.2%] dropdown-container-navbar"
                        onClick={() => toggleDropdownElementsNavbar(1)}
                    >
                        <div className="py-[3px] px-2">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsNavbar === 1 && (
                            <div className="absolute z-50 mt-2 w-36 bg-bodyBackground rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    View
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Go
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Run
                                </p>
                                <p
                                    onClick={() => setOpenTerminal(true)}
                                    className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer"
                                >
                                    Terminal
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Help
                                </p>
                            </div>
                        )}
                    </div>

                    <p className="py-[3px] px-2 mr-[0.4%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden lmd:block">
                        View
                    </p>

                    {/*Dropdown for Go, Run, Terminal, Help*/}
                    <div
                        className="hidden lmd:block rlg:hidden rounded-md hover:bg-[#2d2f2e] cursor-default ml-[1.5%] mr-[0.2%] dropdown-container-navbar"
                        onClick={() => toggleDropdownElementsNavbar(2)}
                    >
                        <div className="py-[3px] px-2">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsNavbar === 2 && (
                            <div className="absolute z-50 mt-2 w-36 bg-bodyBackground rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Go
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Run
                                </p>
                                <p
                                    onClick={() => setOpenTerminal(true)}
                                    className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer"
                                >
                                    Terminal
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Help
                                </p>
                            </div>
                        )}
                    </div>

                    <p className="py-[3px] px-2 mr-[0.3%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden rlg:block">
                        Go
                    </p>

                    {/*Dropdown for Run , Terminal, Help*/}
                    <div
                        className="hidden rlg:block lxl:hidden rounded-md hover:bg-[#2d2f2e] cursor-default ml-[1.5%] mr-[0.2%] dropdown-container-navbar"
                        onClick={() => toggleDropdownElementsNavbar(3)}
                    >
                        <div className="py-[3px] px-2">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsNavbar === 3 && (
                            <div className="absolute z-50 mt-2 w-36 bg-bodyBackground rounded-md shadow-lg text-[10px] font-normal">
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Run
                                </p>
                                <p
                                    onClick={() => setOpenTerminal(true)}
                                    className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer"
                                >
                                    Terminal
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Help
                                </p>
                            </div>
                        )}
                    </div>

                    <p className="py-[3px] px-2 mr-[0.3%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden lxl:block">
                        Run
                    </p>

                    {/*Dropdown for Terminal, Help*/}
                    <div
                        className="hidden lxl:block rxl:hidden rounded-md hover:bg-[#2d2f2e] cursor-default ml-[1.5%] mr-[0.2%] dropdown-container-navbar"
                        onClick={() => toggleDropdownElementsNavbar(4)}
                    >
                        <div className="py-[3px] px-2">
                            <VscEllipsis size={iconSize} />
                        </div>
                        {/* Dropdown */}
                        {showDropdownElementsNavbar === 4 && (
                            <div className="absolute z-50 mt-2 w-36 bg-bodyBackground rounded-md shadow-lg text-[10px] font-normal">
                                <p
                                    onClick={() => setOpenTerminal(true)}
                                    className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer"
                                >
                                    Terminal
                                </p>
                                <p className="py-1 px-4 rounded hover:bg-myBlue cursor-pointer">
                                    Help
                                </p>
                            </div>
                        )}
                    </div>

                    <p
                        onClick={() => setOpenTerminal(true)}
                        className="py-[3px] px-2 mr-[0.4%] rounded-md hover:bg-[#2d2f2e] cursor-default hidden rxl:block"
                    >
                        Terminal
                    </p>
                    <p className="py-[3px] px-2 rounded-md hover:bg-[#2d2f2e] cursor-default hidden rxl:block">
                        Help
                    </p>
                </div>

                {/*Arrows*/}
                <div className="flex justify-end gap-1 w-[40%] rlg:w-full">
                    <div className="rounded-md hover:bg-[#2d2f2e] cursor-pointer py-[1.5px] px-[3px] ">
                        <VscArrowLeft size={iconSize} />
                    </div>
                    <div className="rounded-md py-[1.5px] px-[3px] ">
                        <VscArrowRight size={iconSize} className="opacity-50" />
                    </div>
                </div>
            </div>

            {/*Name*/}
            <div className="w-[118%] bg-[#242424] border border-gray-700 rounded-md pb-[0.4%] pt-[0.5%] text-[5px] sm:text-[9px] lg:text-[10px] font-montserrat text-center text-gray-300 flex justify-center items-center -ml-[2.5%]">
                <div className="cursor-not-allowed flex justify-center items-center gap-1">
                    <LiaSearchSolid size={iconSize - 1} className="mb-0.5" />
                    <p>NAJI IlYess - Survivor - Portfolio</p>
                </div>
            </div>

            {/*Dispositions*/}
            <div className="flex justify-end h-full items-center">
                <div className="hidden lmd:flex gap-[7.8%] mr-[4%]">
                    <div className="cursor-pointer hover:bg-[#2d2f2e] p-0.5 rounded">
                        <VscLayoutSidebarLeft size={16} />
                    </div>
                    <div className="cursor-pointer hover:bg-[#2d2f2e] p-0.5 rounded">
                        <VscLayoutPanelOff size={16} />
                    </div>
                    <div className="cursor-pointer hover:bg-[#2d2f2e] p-0.5 rounded">
                        <VscLayoutSidebarRightOff size={16} />
                    </div>
                    <div className="cursor-pointer hover:bg-[#2d2f2e] p-0.5 rounded">
                        <VscLayout size={16} />
                    </div>
                </div>
                <div className="flex">
                    <div className="px-[7.5px] sm:px-[14.5px] py-[8px] hover:bg-[#2d2f2e] hover:text-white">
                        <VscChromeMinimize size={iconSize} />
                    </div>
                    <div className="px-[7.5px] sm:px-[14.5px] py-[8px] hover:bg-[#2d2f2e] hover:text-white">
                        <VscChromeRestore size={iconSize} />
                    </div>
                    <div className="px-[7.5px] sm:px-[14.5px] py-[8px] hover:bg-[#e71123] hover:text-white">
                        <VscChromeClose size={iconSize} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
