import Icon from "./utils/Icon";
import experiences from "../../assets/jsons/experiences";
import OneExperience from "./utils/OneExperience";

const WorkExperience = () => {
    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="Work Experiences" />
            </div>
            <div className="my-12 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    Early Career{" "}
                    <span className="text-myBlue">Software Engineer</span>{" "}
                    Experience
                </p>
            </div>
            <div className="relative w-full pt-6 -ml-6 flex justify-center">
                <div className="absolute top-8 left-10 llg:left-1/2 border border-gray-400 h-full w-[1px]"></div>

                <div className="hidden llg:flex flex-col gap-[10.3vh] mt-[8vh]">
                    {experiences.map((exp) => (
                        <div key={exp.id}>
                            {exp.id % 2 === 1 && (
                                <div className="w-full grid grid-cols-2">
                                    <div className="relative text-right text-lg tracking-wide mr-[6vh] text-gray-400">
                                        {exp.date[0]} - {exp.date[1]}
                                        {/*Button Inside*/}
                                        <div className="absolute w-4 h-4 rounded-full border-4 bg-myBlue blur-sm -right-[7vh] top-[0.7vh]"></div>
                                        <div className="absolute w-4 h-4 rounded-full border-4 bg-blue-200 border-myBlue -right-[7vh] top-[0.7vh]"></div>
                                    </div>
                                    <div className="ml-[6vh]">
                                        <OneExperience
                                            title={exp.title}
                                            location={exp.location}
                                            desc={exp.desc}
                                        />
                                    </div>
                                </div>
                            )}
                            {exp.id % 2 !== 1 && (
                                <div className="w-full grid grid-cols-2">
                                    <div className="mr-[6vh]">
                                        <OneExperience
                                            title={exp.title}
                                            location={exp.location}
                                            desc={exp.desc}
                                        />
                                    </div>
                                    <div className="relative text-left ml-[6vh] text-lg tracking-wide text-gray-400">
                                        {exp.date[0]} - {exp.date[1]}
                                        {/*Button Inside*/}
                                        <div className="absolute w-4 h-4 rounded-full border-4 bg-myBlue blur-sm -left-[7vh] top-[0.7vh]"></div>
                                        <div className="absolute w-4 h-4 rounded-full border-4 bg-blue-200 border-myBlue -left-[7vh] top-[0.7vh]"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="llg:hidden w-full ml-10 flex flex-col gap-[10.3vh] mt-[8vh]">
                    {experiences.map((exp) => (
                        <div key={exp.id} className="relative">
                            <div className="absolute w-4 h-4 rounded-full border-4 bg-myBlue blur-sm -left-[0.45rem] top-[0.2rem]"></div>
                            <div className="absolute w-4 h-4 rounded-full border-4 bg-blue-200 border-myBlue -left-[0.45rem] top-[0.2rem]"></div>

                            <div className="w-full ml-[7%]">
                                <div className="">
                                    <p className="text-left font-montserrat text-[15px] lmd:text-base llg:text-lg tracking-wide text-gray-400">
                                        {exp.date[0]} - {exp.date[1]}
                                    </p>
                                </div>
                                {/*Button Inside*/}

                                <div className="">
                                    <OneExperience
                                        title={exp.title}
                                        location={exp.location}
                                        desc={exp.desc}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorkExperience;
