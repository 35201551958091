import React, { useEffect, useState } from "react";
import GitHubCalendar from "react-github-calendar";

const GitCalendar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                Days I <span className="text-myBlue">Code</span>
            </p>
            <div className="mt-6 md:mt-14 px-4 scale-100 md:scale-90 flex justify-center overflow-hidden">
                <GitHubCalendar
                    username="NAJIilyass"
                    blockSize={
                        windowWidth > 1300
                            ? 15
                            : windowWidth > 1120
                            ? 11
                            : windowWidth > 1010
                            ? 9
                            : 6
                    }
                    blockMargin={5}
                    color="#000000"
                    fontSize={windowWidth > 1300 ? 16 : 13}
                />
            </div>
        </div>
    );
};
export default GitCalendar;
