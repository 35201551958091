import { FiGithub, FiLinkedin } from "react-icons/fi";

const ContactMeGit = () => {
    return (
        <div className="text-white w-full h-full justify-between transform rotate-90 origin-left flex items-center gap-10">
            <div className="flex gap-4">
                {/*GitHub*/}
                <div className="rotate-180">
                    <div className="rotate-90 cursor-pointer hover:text-myBlue hover:translate-x-0.5 hover:transition duration-500 ease-ease-in-out">
                        <a
                            href="https://github.com/NAJIilyass"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                window.open(
                                    "https://github.com/NAJIilyass",
                                    "_blank"
                                )
                            }
                        >
                            <FiGithub size={22} className="hidden lmd:block" />
                            <FiGithub size={19} className="lmd:hidden" />
                        </a>
                    </div>
                </div>

                {/*LinkedIn*/}
                <div className="rotate-180">
                    <div className="rotate-90 cursor-pointer hover:text-myBlue hover:translate-x-0.5 hover:transition duration-500 ease-ease-in-out">
                        <a
                            href="https://www.linkedin.com/in/naji-ilyass/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/naji-ilyass/",
                                    "_blank"
                                )
                            }
                        >
                            <FiLinkedin
                                size={22}
                                className="hidden lmd:block"
                            />
                            <FiLinkedin size={19} className="lmd:hidden" />{" "}
                        </a>
                    </div>
                </div>
            </div>
            <div className="-mt-[14px] z-10">
                <p className="font-extrabold tracking-tight">______________</p>
            </div>
        </div>
    );
};

export default ContactMeGit;
