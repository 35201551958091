import ThreeContacts from "./ThreeContacts";
import Icon from "./utils/Icon";

const Contact = () => {
    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="Contact Me" />
            </div>
            <div className="my-12 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    Let's Create Excellence{" "}
                    <span className="text-myBlue">Together</span>
                </p>
            </div>

            <div className="pt-14">
                <ThreeContacts />
            </div>

            <div></div>
        </div>
    );
};

export default Contact;
