import OneContact from "./utils/OneContact";

const ThreeContacts = () => {
    return (
        <div className="flex llg:flex flex-col llg:flex-row gap-[1rem] lsm:gap-0 lsm:grid grid-cols-2 justify-center justify-items-center items-center pr-[3vw]">
            <div className="scale-90">
                <OneContact name={"EMAIL"} info={"najiilyassoo@gmail.com"} />
            </div>
            <div className="scale-90">
                <OneContact name={"PHONE"} info={"+212 660-021423"} />
            </div>
            <div className="hidden llg:block scale-90">
                <OneContact name={"LOCATION"} info={"Rabat, Morocco"} />
            </div>
            <div className="scale-90 llg:hidden">
                <OneContact name={"LINKEDIN"} info={"naji-ilyass"} />
            </div>
            <div className="scale-90 llg:hidden">
                <OneContact name={"GITHUB"} info={"NAJIilyass"} />
            </div>
            <div className="scale-90 xs:col-span-2 xs:flex xs:justify-center xs:pl-2.5 llg:hidden">
                <OneContact name={"LOCATION"} info={"Rabat, Morocco"} />
            </div>
        </div>
    );
};

export default ThreeContacts;
