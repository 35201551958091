import Icon from "./utils/Icon";
import ilyess from "../../assets/IlYess'.png";
import oracle from "../../assets/oracle.png";
import INPTCard from "./utils/INPTCard";
import { useEffect, useState } from "react";

const ReadMe = ({ isSideBarDisplayed }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="text-white pb-[50vh]">
            <div>
                <Icon name="README.md" />
            </div>
            <div className="my-12 w-[68%]">
                <p className="text-[25px] rmd:text-[28px] lmd:text-[36px] rlg:text-[45px] font-jaini tracking-wider">
                    I'm a{" "}
                    <span className="text-myBlue">software developer</span>{" "}
                    specialized on{" "}
                    <span className="text-myBlue">Web Applications</span>
                </p>
            </div>

            {/*Texts and Image*/}
            <div
                className={`flex flex-col gap-[4vh] rlg:gap-0 justify-between items-center ${
                    isSideBarDisplayed && windowWidth > 1000
                        ? "flex-col lxl:flex-row"
                        : "rlg:flex-row"
                }`}
            >
                {/*Texts*/}
                <div>
                    <div>
                        <p className="font-semiboldtracking-wide text-[15px] lmd:text-[18px]">
                            NAJI Ilyass
                        </p>
                    </div>
                    <div className="mt-1 mb-6">
                        <p className="font-semibold text-myBlue text-[13.3px] lmd:text-[16px]">
                            Full-Stack Web Developer
                        </p>
                    </div>
                    <div className="flex flex-col gap-3 text-gray-400 text-justify tracking-wide w-[90%] text-[13px] lmd:text-[15px]">
                        <p>
                            I'm a software engineer based in Morocco, constantly
                            seeking innovative solutions to everyday problems.
                        </p>
                        <p>
                            My expertise lies in developing end-to-end
                            applications that prioritize optimal user
                            experiences.
                        </p>
                        <p>
                            I hope you enjoy exploring my portfolio, and I
                            eagerly await the opportunity to breathe life into
                            your next project.
                        </p>
                        <p className="text-[#c66035] brightness-150 font-reddit-mono mt-3 italic">
                            Let's connect and create something amazing together!
                        </p>
                    </div>
                </div>

                {/*Image*/}
                <div className="rlg:w-[25%]">
                    {" "}
                    <div className="w-[10rem] lmd:w-[14rem] h-[10rem] lmd:h-[14rem] mt-[4vh] flex justify-center items-center">
                        <img
                            src={ilyess}
                            alt="IlYess"
                            className="rounded-full"
                        />
                    </div>
                </div>
            </div>

            {/*Languages and Card*/}
            <div className="mt-12 rlg:mt-24 flex flex-col rlg:flex-row justify-between items-center">
                {/*Languages And Post*/}
                <div className="flex flex-col w-full pl-1 pr-5 lsm:pl-7 lsm:pr-20 rlg:w-fit gap-[3vh]">
                    {/*Languages*/}
                    <div className="flex flex-col gap-2">
                        <div>
                            <p className="text-myBlue font-semibold">
                                Languages
                            </p>
                        </div>
                        <div className="flex gap-2 rxl:gap-4 border-y border-gray-700 py-2">
                            <p>
                                <span className="text-[13px] xs:text-[15px] lxl:text-[18px] font-medium">
                                    English
                                </span>{" "}
                                <span className="text-[11px] xs:text-[13px] lxl:text-sm italic text-gray-400">
                                    (Fluent),
                                </span>
                            </p>
                            <p>
                                <span className="text-[13px] xs:text-[15px] lxl:text-[18px] font-medium">
                                    French
                                </span>{" "}
                                <span className="text-[11px] xs:text-[13px] lxl:text-sm italic text-gray-400">
                                    (Advanced),
                                </span>
                            </p>
                            <p>
                                <span className="text-[13px] xs:text-[15px] lxl:text-[18px] font-medium">
                                    Arabic
                                </span>{" "}
                                <span className="text-[11px] xs:text-[13px] lxl:text-sm italic text-gray-400">
                                    (Native)
                                </span>
                            </p>
                        </div>
                    </div>

                    {/*Post*/}
                    <div className="flex flex-col gap-2">
                        <div>
                            <p className="text-myBlue font-semibold">
                                Current Position
                            </p>
                        </div>
                        <div className="flex items-center gap-4 border-y border-gray-700 py-2">
                            <div>
                                <p className="text-[13px] xs:text-[15px] lxl:text-[18px] font-medium">
                                    Research Assistant at Oracle
                                </p>
                            </div>

                            <div>
                                <img
                                    src={oracle}
                                    alt="Oracle"
                                    className="w-7 xs:w-10 lmd:w-12"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Card*/}
                <div className="hidden md:flex w-full rlg:w-fit justify-center pl-7 pr-24 rlg:pl-0 rlg:pr-0 lxl:pr-10 mt-20 rlg:mt-0">
                    <INPTCard />
                </div>
            </div>
        </div>
    );
};

export default ReadMe;
