const OneExperience = ({ title, location, desc }) => {
    const listLocation = [
        "Administration des Douanes et Impôts Indirects",
        "Adelphatech Services, Rabat, Morocco",
        "National Institute of Posts and Telecommunications (INPT), Rabat, Morocco",
        "Omar Ibn Abdelaziz High School, Oujda, Morocco",
    ];
    const handleClick = () => {
        if (location === listLocation[0]) {
            window.open("https://www.douane.gov.ma/web/guest", "_blank");
        } else if (location === listLocation[1]) {
            window.open("https://www.adelphatech.com/", "_blank");
        } else if (location === listLocation[2]) {
            window.open("https://www.inpt.ac.ma/", "_blank");
        } else if (location === listLocation[3]) {
            window.open("https://www.cpge.ac.ma/", "_blank");
        }
    };

    return (
        <div className=" text-left xs:text-justify llg:text-left llg:w-[27vw]">
            <div>
                <p className="font-medium text-base llg:text-lg tracking-wider">
                    {title}
                </p>
            </div>
            <div>
                <p
                    onClick={handleClick}
                    className={`text-myBlue text-[14px] llg:text-base ${
                        listLocation.includes(location)
                            ? "cursor-pointer hover:underline decoration-1 underline-offset-2"
                            : ""
                    }`}
                >
                    {location}
                </p>
            </div>
            <div>
                <p className="text-gray-400 mt-3 text-[14px] llg:text-base tracking-tight">
                    {desc}
                </p>
            </div>
        </div>
    );
};

export default OneExperience;
