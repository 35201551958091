import Typewriter from "typewriter-effect";
import morocco from "../../assets/morocco.webp";
import ScrollDown from "./utils/ScrollDown";

const Intro = () => {
    return (
        <div className="relative text-white pb-[50vh]">
            <div className="mt-36 xs:mt-52 mb-12 xs:mb-0 flex flex-col xs:flex-row items-center gap-4 text-[40px] rsm:text-[50px] lmd:text-[70px] text-white font-mono tracking-wider">
                <p className="font-jaini">NAJI IlYess </p>
                <img
                    src={morocco}
                    alt="🇲🇦"
                    className="w-[80px] rsm:w-[110px] lmd:w-[140px] h-[45px] rsm:h-[62px] lmd:h-[80px] mt-[1%] ml-2"
                />
                <p>
                    <span className="wave" role="img" aria-labelledby="wave">
                        👋🏻
                    </span>
                </p>
            </div>
            <div className="flex text-nowrap items-center gap-1 font-montserrat text-[12px] footerCRLF:text-[14px] lmd:text-base pl-[0.3%] text-center justify-center xs:text-left xs:justify-start">
                <p>I'm a software engineer,</p>
                <div>
                    <Typewriter
                        options={{
                            strings: [
                                " passionate.",
                                " highly skilled.",
                                " a fast-learner.",
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 200,
                        }}
                    />
                </div>
            </div>
            <div className="font-montserrat pl-[0.4%] mx-12 xs:mx-0 text-[12px] footerCRLF:text-[14px] lmd:text-base text-center xs:text-left">
                <p>
                    A 21-year-old software engineer with expertise in Front-End
                    and Back-End technologies.
                </p>
            </div>

            <ScrollDown />
        </div>
    );
};

export default Intro;
