import { useEffect, useState } from "react";
import { FaDocker } from "react-icons/fa";
import {
    VscAccount,
    VscDebugAlt,
    VscExtensions,
    VscFiles,
    VscSearch,
    VscSettingsGear,
    VscSourceControl,
} from "react-icons/vsc";

const MiniSideBar = ({
    whatClicked,
    setWhatClicked,
    isSideBarDisplayed,
    setIsSideBarDisplayed,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [iconSize] = useState(windowWidth < 768 ? 18 : 21);

    return (
        <div className="flex flex-col justify-between text-3xl min-w-[40px] w-[40px] bg-menuBackground text-gray-500 border-r-[0.3px] border-gray-700">
            <div>
                {/* Explorer */}
                <div
                    onClick={() => {
                        if (whatClicked === "explorer") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("explorer");
                    }}
                    className={
                        whatClicked === "explorer"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscFiles size={iconSize} />
                </div>

                {/*Search*/}
                <div
                    onClick={() => {
                        if (whatClicked === "search") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("search");
                    }}
                    className={
                        whatClicked === "search"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscSearch size={iconSize} />
                </div>

                {/*Source Control*/}
                <div
                    onClick={() => {
                        if (whatClicked === "control") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("control");
                    }}
                    className={
                        whatClicked === "control"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscSourceControl size={iconSize} />
                </div>

                {/* Run and Debug */}
                <div
                    onClick={() => {
                        if (whatClicked === "debug") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("debug");
                    }}
                    className={
                        whatClicked === "debug"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscDebugAlt size={iconSize} />
                </div>

                {/* Extensions */}
                <div
                    onClick={() => {
                        if (whatClicked === "extensions") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("extensions");
                    }}
                    className={
                        whatClicked === "extensions"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscExtensions size={iconSize} />
                </div>

                {/* Docker */}
                <div
                    onClick={() => {
                        if (whatClicked === "docker") {
                            setIsSideBarDisplayed(!isSideBarDisplayed);
                        } else {
                            setIsSideBarDisplayed(true);
                        }
                        setWhatClicked("docker");
                    }}
                    className={
                        whatClicked === "docker"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <FaDocker size={iconSize} />
                </div>
            </div>

            <div>
                {/* Accounts */}
                <div
                    onClick={(e) => setWhatClicked("accounts")}
                    className={
                        whatClicked === "accounts"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscAccount size={iconSize} />
                </div>

                {/* Manage */}
                <div
                    onClick={(e) => setWhatClicked("manage")}
                    className={
                        whatClicked === "manage"
                            ? "text-white border-l-[1.6px] border-[#0178d4] flex justify-center items-center w-full py-[24%] cursor-pointer"
                            : "flex justify-center items-center w-full py-[24%] cursor-pointer"
                    }
                >
                    <VscSettingsGear size={iconSize} />
                </div>
            </div>
        </div>
    );
};

export default MiniSideBar;
