import OneSkill from "./utils/OneSkill";
import {
    skills,
    skillsForHexagonWidth,
    skillsFormd,
} from "../../assets/jsons/skills";

const MultipleSkills = () => {
    return (
        <div>
            <div className="hidden hexagon:flex flex-col gap-[48px]">
                {skills.map((group, index) => (
                    <div
                        key={index}
                        className="flex  justify-center items-center gap-[29px]"
                    >
                        {group.map((skill) => (
                            <div key={skill.id}>
                                <OneSkill name={skill.name} icon={skill.icon} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="hidden hexagon:hidden md:flex flex-col gap-[48px]">
                {skillsForHexagonWidth.map((group, index) => (
                    <div
                        key={index}
                        className="flex  justify-center items-center gap-[29px]"
                    >
                        {group.map((skill) => (
                            <div key={skill.id}>
                                <OneSkill name={skill.name} icon={skill.icon} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="md:hidden items-center flex flex-col">
                {skillsFormd.map((skill) => (
                    <div key={skill.id}>
                        <OneSkill name={skill.name} icon={skill.icon} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultipleSkills;
