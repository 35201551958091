const gitignore = [
    {
        id: 1,
        comment: true,
        text: "Embrace the chaos; it's where the fun begins",
    },
    {
        id: 2,
        comment: false,
        text: ".survivor/where_the_fun_begins/*",
    },
    {
        id: 3,
        comment: true,
        text: "Remember: 'First, solve the problem. Then, write the code.' - John Johnson",
    },
    {
        id: 4,
        comment: false,
        text: ".survivor/problem_solving.txt",
    },
    {
        id: 5,
        comment: true,
        text: "Focus: 'The road to success is always under construction.' - Lily Tomlin",
    },
    {
        id: 6,
        comment: false,
        text: ".survivor/road_to_success.log",
    },
    {
        id: 7,
        comment: true,
        text: "Let's not ignore humor: 'Debugging is like being the detective in a crime movie where you are also the murderer.' - Filipe Fortes",
    },
    {
        id: 8,
        comment: false,
        text: ".survivor/debugging.md",
    },
    {
        id: 9,
        comment: true,
        text: "Don't forget about node_modules; they're like the treasure chest in the attic you never visit.",
    },
    {
        id: 10,
        comment: false,
        text: "node_modules/",
    },
];

export default gitignore;
