import React from "react";

const OneProject = ({ name, tools, description }) => {
    return (
        <div className="card-body">
            <div className="relative flex items-center py-4 text-center justify-center border-b border-[#312e81]">
                <div className="flex gap-2 absolute top-[40%] left-[7%]">
                    <div className="h-2 w-2 lg:h-3 lg:w-3 rounded-full bg-red-400"></div>
                    <div className="h-2 w-2 lg:h-3 lg:w-3 rounded-full bg-orange-400"></div>
                    <div className="h-2 w-2 lg:h-3 lg:w-3 rounded-full bg-green-200"></div>
                </div>
                {name !== "Angular-Spring-Dockerized-App" && (
                    <p className="font-montserrat text-center ml-[20%] footerSpace:ml-[4.6%] text-[#16f2b3] text-[12px] footerSpace:text-[13px] xs:text-[14px] rmd:text-[15px] md:text-base lxl::text-xl tracking-wide">
                        {name}
                    </p>
                )}
                {name === "Angular-Spring-Dockerized-App" && (
                    <p className="hidden rsm:block font-montserrat text-center ml-[4.6%] text-[#16f2b3] text-[13px] xs:text-[14px] rmd:text-[15px] md:text-base lxl::text-xl tracking-wide">
                        {name}
                    </p>
                )}
                {name === "Angular-Spring-Dockerized-App" && (
                    <p className="rsm:hidden font-montserrat text-center ml-[20%] footerSpace:ml-[4.6%] text-[#16f2b3] text-[12px] footerSpace:text-[13px] xs:text-[14px] tracking-wide">
                        Dockerized-App
                    </p>
                )}
            </div>
            <div
                className={`font-mono text-[12px] xs:text-[13px] md:text-[15px] lxl:text-base mx-[6.8%] my-[3.5%] ${
                    name === "INELearn" ? "mt-[6%]" : ""
                } `}
            >
                <div className="blink">
                    <span className="mr-2 text-pink-500">const</span>
                    <span className="mr-2 text-white">project</span>
                    <span className="mr-2 text-pink-500">=</span>
                    <span className="text-gray-400">{"{"}</span>
                </div>
                <div>
                    <span className="ml-4 lg:ml-8 mr-2 text-white">name:</span>
                    <span className="text-gray-400">{`'`}</span>
                    <span className="text-amber-300">{name}</span>
                    <span className="text-gray-400">{`',`}</span>
                </div>
                <div className="ml-4 lg:ml-8 mr-2">
                    <span className=" text-white">tools:</span>
                    <span className="text-gray-400">{` ['`}</span>
                    {tools.map((tag, i) => (
                        <React.Fragment key={i}>
                            <span className="text-amber-300">{tag}</span>
                            {tools.length - 1 !== i && (
                                <span className="text-gray-400">{`', '`}</span>
                            )}
                        </React.Fragment>
                    ))}
                    <span className="text-gray-400">{"'],"}</span>
                </div>
                <div className="ml-4 lg:ml-8 mr-2">
                    <span className="text-white">Description:</span>
                    <span className="text-cyan-400">{" " + description}</span>
                    <span className="text-gray-400">,</span>
                </div>
                <div>
                    <span className="text-gray-400">{`};`}</span>
                </div>
            </div>
        </div>
    );
};

export default OneProject;
