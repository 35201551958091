const experiences = [
    {
        id: 1,
        title: "Software engineering intern",
        location: "Administration des Douanes et Impôts Indirects",
        date: ["MAR 2024", "MAY 2024"],
        desc: "I led the development of a critical tool, the 'REST2SOAP Converter'. This solution seamlessly translated REST services into SOAP, optimizing communication processes. Through rigorous research and analysis, I identified the need and implemented a robust translator. I also conducted comparative analyses, ensuring our solution met high standards of efficiency. My contributions streamlined communication within CMR's infrastructure, showcasing my ability to drive innovation and deliver impactful solutions.",
    },
    {
        id: 2,
        title: "Full Stack Developer - EscolaLik",
        location: "Freelance",
        date: ["NOV 2023", "APR 2024"],
        desc: "Within a collaborative team environment, I contributed to the development of EscolaLik, an innovative platform aimed at bridging the gap between teachers and students. Employing Scrum methodologies, we efficiently translated ideas into action, with a prototype visualized through Figma. Leveraging the MERN stack, I constructed a reservation system with integrated calendars and authentication mechanisms, alongside an intuitive admin dashboard for application management. As a Full Stack Developer, I orchestrated the seamless integration of frontend and backend components, ensuring a cohesive and transformative user experience.",
    },
    {
        id: 3,
        title: "Web Developer Intern",
        location: "Adelphatech Services, Rabat, Morocco",
        date: ["JUL 2023", "SEP 2023"],
        desc: "I played a pivotal role in developing a web-based recruitment application aimed at streamlining hiring processes. Conducting extensive user needs analysis, I utilized UML for meticulous system design modeling. Leveraging VueJS and TailwindCSS, I spearheaded the frontend development, ensuring an intuitive and visually appealing user interface. My dedication to user-centric design and technical expertise contributed to the creation of a robust solution poised to revolutionize the recruitment industry.",
    },
    {
        id: 4,
        title: "Intern",
        location:
            "Direction Provinciale de l'Éducation Nationale, Taourirt, Morocco",
        date: ["JUN 2022", "JUL 2022"],
        desc: "I actively contributed to advancing the integration of digitization into Morocco's educational landscape. Through targeted initiatives, I raised awareness among education stakeholders about the transformative potential of digital tools. Additionally, I provided valuable support in resolving issues related to the Massar and Minhaty platforms, thus ensuring their effective implementation. My dedication to promoting technology-enhanced learning underscored my commitment to driving positive change within the Moroccan education sector.",
    },
];

export default experiences;
