const Copyright = () => {
    return (
        <div className="w-full text-center flex justify-center gap-1 text-white font-montserrat mb-[1%] llg:mb-[2%] pr-[4vw] text-[11px] xs:text-sm llg:text-base">
            <p>2024 | Developed with Passion by </p>
            <a
                href="https://github.com/NAJIilyass"
                className="font-semibold text-myBlue hover:underline decoration-1 underline-offset-2"
            >
                IlYess
            </a>
        </div>
    );
};

export default Copyright;
