import nodeJs from "../assets/node-js.png";
import git from "../assets/git.png";
import no_content from "../assets/no_content.png";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useEffect, useState } from "react";
import FolderComponent from "./SideBarComponents/FolderComponent";
import FileComponent from "./SideBarComponents/FileComponent";
import {
    VscCollapseAll,
    VscNewFile,
    VscNewFolder,
    VscRefresh,
} from "react-icons/vsc";

const SideBar = ({
    whatClicked,
    miniActif,
    handleAddMiniList,
    handleScrollToIDHome,
    readmeRef,
    servicesRef,
    workExperienceRef,
    educationRef,
    skillsRef,
    myWorkRef,
    contactRef,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //Update the windows width
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [isSideBarHovered, setIsSideBarHovered] = useState(false);

    const [whatIsClicked, setWhatIsClicked] = useState("portfolio");
    const [whatIsClickedInsidePortfolio, setWhatIsClickedInsidePortfolio] =
        useState("public");
    const [whatIsClickedToBlueBachground, setWhatIsClickedToBlueBachground] =
        useState("");
    const [whatIsClickedInsidePublic, setWhatIsClickedInsidePublic] =
        useState("");

    const filesPublic = [
        { id: 1, name: "README.md", path: "readme" },
        { id: 2, name: "Services", path: "services" },
        { id: 3, name: "Work Experiences", path: "work-experience" },
        { id: 4, name: "Education", path: "education" },
        { id: 5, name: "Skills", path: "skills" },
        { id: 6, name: "My Work", path: "my-work" },
        { id: 7, name: "Contact Me", path: "contact" },
    ];

    // To open or close the arrow in left of the OPEN EDITORS, PORTFOLIO...
    const handleWhatIsClicked = (field) => {
        if (whatIsClicked === field) setWhatIsClicked("");
        else setWhatIsClicked(field);
    };

    // Inside portfolio : To open or close the arrow in left of the folder component and handle the blue background
    const handleWhatIsClickedInsidePortfolio = (field) => {
        if (whatIsClickedInsidePortfolio === field)
            setWhatIsClickedInsidePortfolio("");
        else setWhatIsClickedInsidePortfolio(field);
        setWhatIsClickedToBlueBachground(field);
    };

    // Inside Public
    const handleWhatIsClickedInsidePublic = (field) => {
        //Arrays of names README...
        let filesArray = [];
        for (let obj of filesPublic) {
            filesArray.push(obj.name);
        }

        if (whatIsClickedInsidePublic === field)
            setWhatIsClickedInsidePublic("");
        else if (
            whatIsClickedInsidePublic === "about_me" &&
            filesArray.includes(field)
        )
            setWhatIsClickedInsidePublic(whatIsClickedInsidePublic);
        else setWhatIsClickedInsidePublic(field);
        setWhatIsClickedToBlueBachground(field);
    };

    // To scroll to a specify section when clicking on  the sidebar
    const handleTheHandleScroll = (file) => {
        if (file.name === "README.md")
            handleScrollToIDHome(file.path, readmeRef);
        else if (file.name === "Services")
            handleScrollToIDHome(file.path, servicesRef);
        else if (file.name === "Work Experiences")
            handleScrollToIDHome(file.path, workExperienceRef);
        else if (file.name === "Education")
            handleScrollToIDHome(file.path, educationRef);
        else if (file.name === "Skills")
            handleScrollToIDHome(file.path, skillsRef);
        else if (file.name === "My Work")
            handleScrollToIDHome(file.path, myWorkRef);
        else if (file.name === "Contact Me")
            handleScrollToIDHome(file.path, contactRef);
    };

    useEffect(() => {
        if (miniActif !== "about_me.js") {
            if (miniActif === "Resume.js") {
                setWhatIsClickedInsidePublic("Resume");
                setWhatIsClickedToBlueBachground("Resume");
            } else if (miniActif === ".gitignore") {
                setWhatIsClickedToBlueBachground(".gitignore");
                setWhatIsClickedInsidePublic("");
            } else {
                setWhatIsClickedToBlueBachground("");
                setWhatIsClickedInsidePublic("");
            }
        } else {
            setWhatIsClickedInsidePublic("about_me");
            setWhatIsClickedToBlueBachground("about_me");
        }
    }, [miniActif]);

    return (
        <div
            onMouseEnter={() => setIsSideBarHovered(true)}
            onMouseLeave={() => setIsSideBarHovered(false)}
            className={`${
                windowWidth < 1000
                    ? "absolute left-[40px] top-[35px] bottom-[16px]"
                    : ""
            } bg-menuBackground text-nowrap z-50 min-w-[150px] w-[150px] border-r-[0.3px] border-gray-700 text-gray-300 text-[9.9px] font-[700] select-none`}
        >
            {/*Content of SideBar while clicking on Explorer on miniSideBar*/}
            {whatClicked === "explorer" && (
                <div className="bg-menuBackground">
                    {/*Explorer*/}
                    <div className="flex justify-between items-center h-[30px] font-[400] pl-[9%] pr-[6%]">
                        <div>
                            <p className="text-[9px]">EXPLORER</p>
                        </div>
                        <div className="flex justify-center items-center hover:bg-[#333335] cursor-pointer rounded text-base text-center tracking-wide pb-2.5 px-1 h-2/3">
                            <p>...</p>
                        </div>
                    </div>

                    {/*Lists*/}
                    <div
                        className={`flex flex-col bg-menuBackground ${
                            whatIsClicked === "portfolio"
                                ? "justify-between"
                                : "justify-start"
                        } ${
                            windowHeight > 1100
                                ? "h-[92.6svh]"
                                : windowHeight > 1000
                                ? "h-[92svh]"
                                : windowHeight > 950
                                ? "h-[91.4svh]"
                                : windowHeight > 900
                                ? "h-[91svh]"
                                : windowHeight > 850
                                ? "h-[90.6svh]"
                                : windowHeight > 800
                                ? "h-[90.5svh]"
                                : windowHeight > 750
                                ? "h-[89.15svh]"
                                : windowHeight > 700
                                ? "h-[89svh]"
                                : windowHeight > 650
                                ? "h-[88svh]"
                                : "h-[87.4svh]"
                        }`}
                    >
                        {/*Open editors and Portfolio*/}
                        <div>
                            {/*Open Editor*/}
                            <div
                                onClick={() => handleWhatIsClicked("open")}
                                className="flex items-center bg-menuBackground gap-0.5 cursor-pointer pb-[3px] border-b-[0.3px] border-gray-800"
                            >
                                {whatIsClicked === "open" ? (
                                    <IoIosArrowDown
                                        size={13}
                                        className="mt-[1px]"
                                    />
                                ) : (
                                    <IoIosArrowForward
                                        size={13}
                                        className="mt-[0.5px]"
                                    />
                                )}
                                <p className="tracking-tight">OPEN EDITORS</p>
                            </div>

                            {/*Portfolio*/}
                            <div>
                                <div
                                    onClick={() =>
                                        handleWhatIsClicked("portfolio")
                                    }
                                    className="flex  items-center gap-0.5 cursor-pointer pb-[3px]"
                                >
                                    {whatIsClicked === "portfolio" ? (
                                        <IoIosArrowDown
                                            size={13}
                                            className="mt-[1px]"
                                        />
                                    ) : (
                                        <IoIosArrowForward
                                            size={13}
                                            className="mt-[0.5px]"
                                        />
                                    )}
                                    <div className="flex justify-between items-center w-[90%]">
                                        <p className="tracking-tight">
                                            PORTFOLIO
                                        </p>

                                        {whatIsClicked === "portfolio" &&
                                            isSideBarHovered && (
                                                <div className="flex gap-1">
                                                    <div className="hover:bg-[#333335] rounded-sm px-[1px] py-[0.7px] cursor-pointer">
                                                        <VscNewFile size={12} />
                                                    </div>

                                                    <div className="hover:bg-[#333335] rounded-sm px-[1px] py-[0.7px] cursor-pointer">
                                                        <VscNewFolder
                                                            size={12}
                                                        />
                                                    </div>

                                                    <div className="hover:bg-[#333335] rounded-sm px-[1px] py-[0.7px] cursor-pointer">
                                                        <VscRefresh size={12} />
                                                    </div>
                                                    <div className="hover:bg-[#333335] rounded-sm px-[1px] py-[0.7px] cursor-pointer">
                                                        <VscCollapseAll
                                                            size={12}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>

                                {/*Content*/}
                                {whatIsClicked === "portfolio" && (
                                    <div className="font-normal bg-menuBackground text-[10.5px] tracking-wide flex flex-col gap-0.5">
                                        {/*node_modules*/}
                                        <div className="flex items-center gap-0.5 cursor-pointer px-[5%] hover:bg-[#292d2e]">
                                            <IoIosArrowForward
                                                size={13}
                                                className="mt-[0.5px]"
                                            />
                                            <div className="flex gap-1.5 items-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="13"
                                                    height="13"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="#8bc34a"
                                                        d="M10 4H4c-1.11 0-2 .89-2 2v12c0 1.097.903 2 2 2h16c1.097 0 2-.903 2-2V8a2 2 0 00-2-2h-8l-2-2z"
                                                    ></path>
                                                    <path
                                                        fill="#dcedc8"
                                                        d="M17.15 8.53l-5.49 3.17c-.21.12-.33.34-.33.57v6.34c0 .24.13.46.33.57l5.49 3.18c.21.12.46.12.66 0l5.49-3.17c.2-.12.33-.34.33-.57v-6.34c0-.24-.13-.46-.33-.57l-5.49-3.18a.656.656 0 00-.66 0"
                                                    ></path>
                                                </svg>

                                                <p className="opacity-50 text-white">
                                                    node_modules
                                                </p>
                                            </div>
                                        </div>

                                        {/*public*/}
                                        <div
                                            onClick={() =>
                                                handleWhatIsClickedInsidePortfolio(
                                                    "public"
                                                )
                                            }
                                            className={`flex items-center gap-0.5 cursor-pointer px-[5%] ${
                                                whatIsClickedToBlueBachground ===
                                                "public"
                                                    ? "bg-[#05395b] border-y border-[#1670AC]"
                                                    : "hover:bg-[#292d2e]"
                                            }`}
                                        >
                                            {/*Arrow*/}
                                            {whatIsClickedInsidePortfolio !==
                                            "public" ? (
                                                <IoIosArrowForward
                                                    size={13}
                                                    className="mt-[0.5px]"
                                                />
                                            ) : (
                                                <IoIosArrowDown
                                                    size={13}
                                                    className="mt-[0.5px]"
                                                />
                                            )}

                                            <div className="flex gap-1.5 items-center">
                                                {/*Open/closed folder*/}
                                                {whatIsClickedInsidePortfolio !==
                                                "public" ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        fillRule="evenodd"
                                                        strokeLinejoin="round"
                                                        strokeMiterlimit="1.414"
                                                        clipRule="evenodd"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="#039be5"
                                                            fillRule="nonzero"
                                                            d="M10 4H4c-1.11 0-2 .89-2 2v12c0 1.097.903 2 2 2h16c1.097 0 2-.903 2-2V8a2 2 0 00-2-2h-8l-2-2z"
                                                        ></path>
                                                        <path
                                                            fill="#b3e5fc"
                                                            d="M20.036 16.746c.05-.408.087-.817.087-1.237s-.037-.83-.087-1.238h2.091c.099.396.16.81.16 1.238a5.1 5.1 0 01-.16 1.237m-3.186 3.44c.371-.687.656-1.43.854-2.203h1.825a4.968 4.968 0 01-2.679 2.203m-.155-3.44h-2.895c-.062-.408-.099-.817-.099-1.237s.037-.835.1-1.238h2.894c.056.403.1.817.1 1.238s-.044.829-.1 1.237m-1.447 3.687a8.39 8.39 0 01-1.182-2.45h2.363a8.39 8.39 0 01-1.181 2.45m-2.475-7.399h-1.806a4.902 4.902 0 012.672-2.202c-.37.686-.65 1.429-.866 2.202m-1.806 4.95h1.806c.217.773.495 1.515.866 2.202a4.954 4.954 0 01-2.672-2.203m-.508-1.237a5.099 5.099 0 01-.16-1.237 5.1 5.1 0 01.16-1.238h2.091c-.049.409-.086.817-.086 1.238s.037.829.086 1.237m2.698-6.168a8.425 8.425 0 011.181 2.456h-2.363a8.426 8.426 0 011.182-2.456m4.28 2.456h-1.824a9.682 9.682 0 00-.854-2.202 4.94 4.94 0 012.679 2.202m-4.281-3.712a6.193 6.193 0 00-6.187 6.187 6.186 6.186 0 006.187 6.186 6.186 6.186 0 006.186-6.186 6.186 6.186 0 00-6.186-6.187z"
                                                        ></path>
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        strokeLinejoin="round"
                                                        strokeMiterlimit="1.414"
                                                        clipRule="evenodd"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="#039be5"
                                                            d="M19 20H4a2 2 0 01-2-2V6c0-1.11.89-2 2-2h6l2 2h7c1.097 0 2 .903 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z"
                                                        ></path>
                                                        <path
                                                            fill="#b3e5fc"
                                                            d="M20.037 16.746c.05-.408.087-.817.087-1.237s-.037-.83-.087-1.238h2.091c.099.396.16.81.16 1.238a5.1 5.1 0 01-.16 1.237m-3.186 3.44c.371-.687.656-1.43.854-2.203h1.825a4.967 4.967 0 01-2.68 2.203m-.154-3.44h-2.895c-.062-.408-.099-.817-.099-1.237s.037-.835.099-1.238h2.895c.056.403.1.817.1 1.238s-.044.829-.1 1.237m-1.447 3.687a8.39 8.39 0 01-1.182-2.45h2.363a8.39 8.39 0 01-1.181 2.45m-2.475-7.399H13.06a4.902 4.902 0 012.672-2.202 10.63 10.63 0 00-.866 2.202m-1.806 4.95h1.806c.217.773.495 1.515.866 2.202a4.954 4.954 0 01-2.672-2.203m-.508-1.237a5.099 5.099 0 01-.16-1.237 5.1 5.1 0 01.16-1.238h2.091c-.05.409-.086.817-.086 1.238s.037.829.086 1.237m2.698-6.168a8.425 8.425 0 011.181 2.456h-2.363a8.426 8.426 0 011.182-2.456m4.28 2.456h-1.824a9.682 9.682 0 00-.854-2.202 4.941 4.941 0 012.679 2.202M17.34 9.322a6.193 6.193 0 00-6.187 6.187 6.186 6.186 0 006.187 6.186 6.186 6.186 0 006.186-6.186 6.186 6.186 0 00-6.186-6.187z"
                                                        ></path>
                                                    </svg>
                                                )}

                                                <p>public</p>
                                            </div>
                                        </div>

                                        {/*About_me folder*/}
                                        {whatIsClickedInsidePortfolio ===
                                            "public" && (
                                            <div
                                                onClick={() => {
                                                    handleWhatIsClickedInsidePublic(
                                                        "about_me"
                                                    );
                                                    handleAddMiniList(
                                                        "about_me.js"
                                                    );
                                                }}
                                                className="-mb-[0.6px]"
                                            >
                                                <FolderComponent
                                                    name={"about_me"}
                                                    path={"about_me.js"}
                                                    whatIsClickedToBlueBachground={
                                                        whatIsClickedToBlueBachground
                                                    }
                                                    whatIsClickedInsidePublic={
                                                        whatIsClickedInsidePublic
                                                    }
                                                    marginLeft={3}
                                                />
                                            </div>
                                        )}

                                        {/*About_me content*/}
                                        {whatIsClickedInsidePortfolio ===
                                            "public" &&
                                            whatIsClickedInsidePublic ===
                                                "about_me" &&
                                            filesPublic.map((file, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        handleWhatIsClickedInsidePublic(
                                                            file.name
                                                        );
                                                        handleAddMiniList(
                                                            "about_me.js",
                                                            file
                                                        );
                                                        handleTheHandleScroll(
                                                            file
                                                        );
                                                    }}
                                                    className="-my-[1.2px]"
                                                >
                                                    <FileComponent
                                                        name={file.name}
                                                        whatIsClickedToBlueBachground={
                                                            whatIsClickedToBlueBachground
                                                        }
                                                        whatIsClickedInsidePublic={
                                                            whatIsClickedInsidePublic
                                                        }
                                                    />
                                                </div>
                                            ))}

                                        {/*About_me folder*/}
                                        {whatIsClickedInsidePortfolio ===
                                            "public" && (
                                            <div
                                                onClick={() => {
                                                    handleWhatIsClickedInsidePublic(
                                                        "Resume"
                                                    );
                                                    handleAddMiniList(
                                                        "Resume.js",
                                                        null
                                                    );
                                                }}
                                                className="-my-[1.6px]"
                                            >
                                                <FileComponent
                                                    name={"Resume"}
                                                    whatIsClickedToBlueBachground={
                                                        whatIsClickedToBlueBachground
                                                    }
                                                    whatIsClickedInsidePublic={
                                                        whatIsClickedInsidePublic
                                                    }
                                                />
                                            </div>
                                        )}

                                        {/*src*/}
                                        <div
                                            onClick={() =>
                                                handleWhatIsClickedInsidePortfolio(
                                                    "src"
                                                )
                                            }
                                            className={`flex items-center gap-0.5 cursor-pointer px-[5%] ${
                                                whatIsClickedToBlueBachground ===
                                                "src"
                                                    ? "bg-[#05395b] border-y border-[#1670AC]"
                                                    : "hover:bg-[#292d2e]"
                                            }`}
                                        >
                                            {whatIsClickedInsidePortfolio !==
                                            "src" ? (
                                                <IoIosArrowForward
                                                    size={13}
                                                    className="mt-[0.5px]"
                                                />
                                            ) : (
                                                <IoIosArrowDown
                                                    size={13}
                                                    className="mt-[0.5px]"
                                                />
                                            )}

                                            <div className="flex gap-1.5 items-center">
                                                {whatIsClickedInsidePortfolio !==
                                                "src" ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        fillRule="evenodd"
                                                        strokeLinejoin="round"
                                                        strokeMiterlimit="1.414"
                                                        clipRule="evenodd"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="#4caf50"
                                                            fillRule="nonzero"
                                                            d="M10 4H4c-1.11 0-2 .89-2 2v12c0 1.097.903 2 2 2h16c1.097 0 2-.903 2-2V8a2 2 0 00-2-2h-8l-2-2z"
                                                        ></path>
                                                        <path
                                                            fill="#c8e6c9"
                                                            fillRule="nonzero"
                                                            d="M15.597 21.678a.637.637 0 01-.147-.018.739.739 0 01-.513-.857l1.915-10.2a.734.734 0 01.314-.482.63.63 0 01.495-.087.74.74 0 01.513.858l-1.92 10.2a.73.73 0 01-.312.481.632.632 0 01-.345.105zm3.839-1.445h-.048a.658.658 0 01-.472-.267.78.78 0 01.07-1.003l3.293-3.218L19 12.75a.78.78 0 01-.1-1 .663.663 0 01.467-.283.636.636 0 01.501.167l3.866 3.534a.78.78 0 01.102 1 .743.743 0 01-.1.116l-3.85 3.763a.646.646 0 01-.451.187zm-5.764.002a.648.648 0 01-.451-.189l-3.854-3.765a.775.775 0 01.006-1.108l3.867-3.533a.623.623 0 01.495-.169c.19.022.361.125.47.283a.78.78 0 01-.099 1.002l-3.277 2.993 3.293 3.216c.266.27.297.699.07 1.003a.656.656 0 01-.472.267z"
                                                        ></path>
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        strokeLinejoin="round"
                                                        strokeMiterlimit="1.414"
                                                        clipRule="evenodd"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="#4caf50"
                                                            d="M19 20H4a2 2 0 01-2-2V6c0-1.11.89-2 2-2h6l2 2h7c1.097 0 2 .903 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z"
                                                        ></path>
                                                        <path
                                                            fill="#c8e6c9"
                                                            d="M15.597 21.678a.637.637 0 01-.147-.018.739.739 0 01-.513-.857l1.915-10.2a.734.734 0 01.314-.482.63.63 0 01.494-.087.74.74 0 01.514.858l-1.92 10.2a.73.73 0 01-.312.481.632.632 0 01-.345.105zm3.839-1.445h-.048a.658.658 0 01-.472-.267.78.78 0 01.07-1.003l3.292-3.218L19 12.75a.78.78 0 01-.1-1 .663.663 0 01.468-.283.636.636 0 01.501.167l3.866 3.534a.78.78 0 01.102 1 .743.743 0 01-.1.116l-3.85 3.763a.646.646 0 01-.451.187zm-5.764.002a.648.648 0 01-.452-.189l-3.853-3.765a.775.775 0 01.006-1.108l3.867-3.533a.623.623 0 01.495-.169c.19.022.361.125.47.283a.78.78 0 01-.1 1.002l-3.276 2.993 3.292 3.216c.267.27.297.699.071 1.003a.656.656 0 01-.473.267z"
                                                        ></path>
                                                    </svg>
                                                )}

                                                <p>src</p>
                                            </div>
                                        </div>

                                        {/*gitingore*/}
                                        <div
                                            onClick={() => {
                                                setWhatIsClickedInsidePublic(
                                                    null
                                                );
                                                setWhatIsClickedToBlueBachground(
                                                    ".gitignore"
                                                );
                                                handleAddMiniList(
                                                    ".gitignore",
                                                    null
                                                );
                                            }}
                                            className={`flex items-center gap-0.5 cursor-pointer px-[5%] py-[0.5%]  ${
                                                whatIsClickedToBlueBachground ===
                                                ".gitignore"
                                                    ? "bg-[#05395b] border-y border-[#1670AC]"
                                                    : "hover:bg-[#292d2e]"
                                            }`}
                                        >
                                            <IoIosArrowForward
                                                size={13}
                                                className="mt-[0.5px] opacity-0"
                                            />
                                            <div className="flex gap-1.5 items-center">
                                                <img
                                                    src={git}
                                                    alt="git"
                                                    className="w-3"
                                                ></img>
                                                <p>.gitignore</p>
                                            </div>
                                        </div>

                                        {/*package-lock.json*/}
                                        <div className="flex items-center gap-0.5 cursor-pointer px-[5%] py-[0.5%] hover:bg-[#292d2e]">
                                            <IoIosArrowForward
                                                size={13}
                                                className="mt-[0.5px] opacity-0"
                                            />
                                            <div className="flex gap-1.5 items-center">
                                                <img
                                                    src={nodeJs}
                                                    alt="nodeJs"
                                                    className="w-3"
                                                ></img>
                                                <p>package-lock.json</p>
                                            </div>
                                        </div>

                                        {/*package.json*/}
                                        <div className="flex items-center gap-0.5 cursor-pointer px-[5%] py-[0.5%] hover:bg-[#292d2e]">
                                            <IoIosArrowForward
                                                size={13}
                                                className="mt-[0.5px] opacity-0"
                                            />
                                            <div className="flex gap-1.5 items-center">
                                                <img
                                                    src={nodeJs}
                                                    alt="nodeJs"
                                                    className="w-3"
                                                ></img>
                                                <p>package.json</p>
                                            </div>
                                        </div>

                                        {/*tailwnind*/}
                                        <div className="flex items-center gap-0.5 cursor-pointer px-[5%] py-[0.5%] hover:bg-[#292d2e]">
                                            <IoIosArrowForward
                                                size={13}
                                                className="mt-[0.5px] opacity-0"
                                            />
                                            <div className="flex gap-[4.4px] items-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14
                                    "
                                                    height="14
                                    "
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="#4db6ac"
                                                        d="M6.5 9.8c.733-2.934 2.567-4.4 5.5-4.4 4.4 0 4.951 3.3 7.151 3.85 1.467.367 2.75-.184 3.85-1.65-.732 2.933-2.566 4.4-5.5 4.4-4.4 0-4.95-3.3-7.15-3.85-1.468-.368-2.751.183-3.852 1.65zM.998 16.4c.733-2.933 2.567-4.4 5.5-4.4 4.401 0 4.95 3.3 7.152 3.85 1.466.368 2.75-.183 3.85-1.65-.734 2.934-2.567 4.4-5.5 4.4-4.401 0-4.951-3.3-7.152-3.85-1.466-.367-2.75.184-3.85 1.65z"
                                                    ></path>
                                                </svg>
                                                <p>tailwind.config.js</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/*OUTLINE and TimeLine*/}
                        <div>
                            {/*Outline*/}
                            <div
                                onClick={() => handleWhatIsClicked("outline")}
                                className="flex items-center bg-menuBackground gap-0.5 cursor-pointer pb-[3px] border-y-[0.3px] border-gray-800"
                            >
                                {whatIsClicked === "outline" ? (
                                    <IoIosArrowDown
                                        size={13}
                                        className="mt-[1px]"
                                    />
                                ) : (
                                    <IoIosArrowForward
                                        size={13}
                                        className="mt-[0.5px]"
                                    />
                                )}
                                <p className="tracking-tight">OUTLINE</p>
                            </div>

                            {/*Timeline*/}
                            <div
                                onClick={() => handleWhatIsClicked("timeline")}
                                className="flex items-center bg-menuBackground gap-0.5 cursor-pointer pb-[3px]"
                            >
                                {whatIsClicked === "timeline" ? (
                                    <IoIosArrowDown
                                        size={13}
                                        className="mt-[2px]"
                                    />
                                ) : (
                                    <IoIosArrowForward
                                        size={13}
                                        className="mt-[0.5px]"
                                    />
                                )}
                                <p className="tracking-tight">TIMELINE</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {whatClicked !== "explorer" && (
                <div className="flex text-wrap flex-col justify-center items-center h-5/6">
                    <img src={no_content} alt="no content" />
                    <div className="text-center flex flex-col gap-2">
                        <p className="font-montserrat text-base">
                            Ooops {whatClicked === "search" && <span>303</span>}
                            {(whatClicked === "control" ||
                                whatClicked === "accounts" ||
                                whatClicked === "manage") && <span>204</span>}
                            {whatClicked === "debug" && <span>202</span>}
                            {whatClicked === "extensions" && <span>426</span>}
                            {whatClicked === "docker" && <span>418</span>}!
                        </p>
                        <div className="text-xs mx-[8%] font-normal italic">
                            {whatClicked === "search" && (
                                <p>
                                    Looking for something specific? Dive into my
                                    portfolio and explore! Results may vary, but
                                    discovery awaits.
                                </p>
                            )}

                            {(whatClicked === "control" ||
                                whatClicked === "accounts" ||
                                whatClicked === "manage") && (
                                <p>
                                    Whoopsie-doodle! Looks like our content
                                    decided to play hide and seek. Swing by
                                    later and let's hope it's done with its game
                                    of disappearing acts!{" "}
                                    <span className="not-italic text-nowrap">
                                        🎩🐰
                                    </span>
                                </p>
                            )}

                            {whatClicked === "debug" && (
                                <p>
                                    Debugging in progress! If you spot something
                                    fishy, please let me know. My code has a
                                    sense of humor... sometimes
                                </p>
                            )}

                            {whatClicked === "extensions" && (
                                <p>
                                    Extensions? Why not! Always room to grow,
                                    learn, and add something new.
                                </p>
                            )}

                            {whatClicked === "docker" && (
                                <div>
                                    <p className="text-nowrap">
                                        It works on my machine !
                                    </p>
                                    <p>But it should work on yours too</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideBar;
