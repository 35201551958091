import React from "react";
import styled from "styled-components";

import react from "../../../assets/skills/react.png";
import java from "../../../assets/skills/java.webp";
import spring from "../../../assets/skills/spring.png";
import node from "../../../assets/skills/node.png";
import tailwind from "../../../assets/skills/tailwind.png";
import git from "../../../assets/skills/git.png";

const Cubo = styled.div`
    position: relative;
    padding-bottom: 5rem;

    .stage-cube-cont {
        position: absolute;

        @media (min-width: 768px) {
            left: 3rem;
        }
    }

    .cubospinner {
        animation: spincube 13s ease-in-out infinite;
        transform-style: preserve-3d;
        transform-origin: 50px 50px 0;
        margin-left: calc(50% - 50px);

        &:hover {
            animation-play-state: paused;
        }

        img {
            width: 4rem;
        }

        div {
            position: absolute;
            width: 100px;
            height: 100px;
            border: 2px solid #1e1e25;
            background: rgba(1, 1, 1, 0.9);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .face1 {
            transform: translateZ(50px);
        }

        .face2 {
            transform: rotateY(90deg) rotateX(90deg) translateZ(50px);
        }

        .face3 {
            transform: rotateY(90deg) translateZ(50px);
            img {
                transform: rotate(-90deg);
            }
        }

        .face4 {
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(50px);
        }

        .face5 {
            transform: rotateY(180deg) rotateZ(90deg) translateZ(50px);
        }

        .face6 {
            transform: rotateX(-90deg) translateZ(50px);
        }
    }

    @keyframes spincube {
        from,
        to {
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }
        16% {
            transform: rotateY(-90deg) rotateZ(90deg);
        }
        33% {
            transform: rotateY(-90deg) rotateX(90deg);
        }
        50% {
            transform: rotateY(-180deg) rotateZ(90deg);
        }
        66% {
            transform: rotateY(-270deg) rotateX(90deg);
        }
        83% {
            transform: rotateX(90deg);
        }
    }
`;

const Cube = () => {
    return (
        <div className="flex justify-center items-center">
            <Cubo>
                <div className="stage-cube-cont">
                    <div className="cubospinner">
                        <div className="face1">
                            <img
                                width={72}
                                height={72}
                                src={react}
                                alt="react"
                            />
                        </div>
                        <div className="face2">
                            <img width={72} height={72} src={java} alt="java" />
                        </div>
                        <div className="face3">
                            <img
                                width={72}
                                height={72}
                                src={tailwind}
                                alt="tailwind"
                            />
                        </div>
                        <div className="face4">
                            <img
                                width={72}
                                height={72}
                                src={spring}
                                alt="spring"
                            />
                        </div>
                        <div className="face5">
                            <img width={72} height={72} src={git} alt="git" />
                        </div>
                        <div className="face6">
                            <img width={72} height={72} src={node} alt="node" />
                        </div>
                    </div>
                </div>
            </Cubo>
        </div>
    );
};

export default Cube;
