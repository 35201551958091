const services = [
    [
        {
            id: 1,
            title: "Software Engineering",
            desc: "Fuel business growth with innovative software solutions. I employ efficient development practices to drive success.",
        },
        {
            id: 2,
            title: "Web Development",
            desc: "Precision-crafted web solutions, built to thrive in the digital landscape. I leverage top-tier practices and tools for peak performance.",
        },
    ],
    [
        {
            id: 1,
            title: "Problem Solving",
            desc: "Navigate complexity with ease. I excel at analyzing issues and delivering effective solutions.",
        },
        {
            id: 2,
            title: "UI/UX Design",
            desc: "Create user-friendly interfaces with a basic understanding of UI/UX principles.",
        },
    ],
];

export default services;
