const education = [
    {
        id: 1,
        title: "Software Engineering Cycle",
        location:
            "National Institute of Posts and Telecommunications (INPT), Rabat, Morocco",
        date: ["SEP 2022", "JUN 2025"],
        desc: "Driven by a passion for creation and construction, I embarked on a journey in Software Engineering. Throughout my academic pursuit, I honed my skills in software development, database design, and system architecture. This educational odyssey has not only equipped me with the essential tools to thrive in the realm of software engineering but also furnished me with a robust groundwork upon which to build my professional career.",
    },
    {
        id: 2,
        title: "Preparatory Cycle - Mathematics and Physics",
        location: "Omar Ibn Abdelaziz High School, Oujda, Morocco",
        date: ["SEP 2020", "JUN 2022"],
        desc: "Engaged in a rigorous academic curriculum, I deepened my understanding of mathematics and physics, establishing a strong educational base. This experience not only enhanced my proficiency but also cultivated essential skills in teamwork and resilience. Driven by determination and dedication, I embraced the challenge with unwavering commitment, maintaining a steadfast work ethic throughout.",
    },
];

export default education;
